import { Space, Spin, Table, Tag } from 'antd'
import { useState } from 'react'
import TintAPI from '../utils/TintAPI'
import DataPagination from '../components/DataPagination'
import SearchInput from '../components/SearchInput'
import useTintAPI from '../hooks/useTintAPI'
import SummaryRow from '../components/SummaryRow'

const ProductsPanel = () => {
    const [search, setSearch] = useState(null)
    const [page, setPage] = useState(1)
    const [sort, setSort] = useState(null)
    const [data, loading] = useTintAPI(TintAPI.getProducts, {
        search: search,
        page: page,
        sort: sort
    }, [search, page, sort])

    const processedData = data?.products

    const onSearch = (search) => {
        setPage(1)
        setSearch(search)
    }
    const onTableChange = (pagination, filter, sorter) => {
        setSort({
            field: sorter.columnKey,
            dir: { ascend: 'asc', descend: 'desc' }[sorter.order]
        })
    }
    const onPageChange = (page) => {
        setPage(page)
    }

    const columns = [
        {
            dataIndex: 'images',
            key: 'image',
            width: '40px',
            render: images => images.length > 0 && <img src={images[0].src} alt="" height="40" />
        },
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
            sorter: (a, b) => 0,
            sortDirections: ['ascend', 'descend'],
            render: (title, row) => (
                <>
                    <div>{title}</div>
                    <div className="subtitle">{row.tags.short_desc}</div>
                </>
            )
        },
        {
            title: 'SKU',
            key: 'sku',
            render: row => row.variants.length === 1 ? row.variants[0].sku : <Tag />
        },
        {
            title: 'Code',
            dataIndex: 'tags',
            key: 'code',
            render: (tags) => tags.code
        },
        {
            title: 'Pro',
            dataIndex: 'tags',
            key: 'pro',
            render: (tags) => (
                <Tag color={tags.pro_only ? 'gold' : null}>{tags.pro_only && 'PRO'}</Tag>
            )
        },
        {
            title: 'Price',
            key: 'price',
            render: row => row.variants.length === 1 ? `$${row.variants[0].price}` : <Tag />
        }
    ]

    const expandedRowRender = product => {
        const columns = [
            {
                key: 'image',
                width: '40px',
                render: () => product.images.length > 0 && <img src={product.images[0].src} alt="" height="40" />
            },
            {
                title: 'Title',
                dataIndex: 'title',
                key: 'title'
            },
            {
                title: 'SKU',
                dataIndex: 'sku',
                key: 'sku'
            },
            {
                title: 'Price',
                dataIndex: 'price',
                key: 'price',
                render: price => `$${price}`
            }
        ]

        return <Table columns={columns} dataSource={product.variants} rowKey={product => product.id}
                      pagination={false} />
    }
    const rowExpandable = product => product.variants.length > 1

    return (
        <>
            <Space direction="vertical" size={64} style={{ width: '100%' }}>
                <SummaryRow items={[{ title: 'Total Products', value: processedData?.paging.total_item_count }]} loading={loading} />
                <div>
                    <SearchInput loading={loading} onSearch={onSearch} />
                    <Spin spinning={loading}>
                        <Table columns={columns} dataSource={processedData?.items} rowKey={product => product.id} expandable={{ expandedRowRender, rowExpandable }} expandRowByClick pagination={false} onChange={onTableChange} />
                    </Spin>
                </div>
            </Space>
            <DataPagination paging={processedData?.paging} itemType="products" onChange={onPageChange} />
        </>
    )
}

export default ProductsPanel

