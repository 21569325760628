import { Tag } from "antd";
import ColorUtils from "../utils/ColorUtils";

const ColorantTags = ({ colorants }) =>
{
    const filtered = [];

    for (const id of Object.keys(colorants))
    {
        const value = colorants[id];

        if (!value)
            continue;

        filtered.push({ id: id.toUpperCase(), value: value });
    }

    const getTagColor = id =>
    {
        switch (id)
        {
            case 'A': return '#FBC812';
            case 'B': return '#3B3C3D';
            case 'C': return '#B4813F';
            case 'D': return '#007055';
            case 'E': return '#1F4FA0';
            case 'F': return '#85453C';
            case 'J': return '#FBC711';
            case 'L': return '#554D45';
            case 'K': return '#BC2F39';
            case 'M': return '#B72E65';
            case 'R': return '#CC353F';
            case 'W': return '#EEEEEA';
            default: return null;
        }
    }

    return (
        <>
            {filtered.map(colorant => {
                const color = getTagColor(colorant.id);
                return (
                    <Tag key={colorant.id} color={color} style={{ fontWeight: 600, color: color && ColorUtils.isDark(color) ? 'white' : 'black' }}>
                        {colorant.id}{colorant.value}
                    </Tag>
                )
            })}
        </>
    );
};

export default ColorantTags;

