import { Divider, Empty, Tag } from "antd";
import "./TintMatchSearchList.scss"
import TintMatchSearchListItem from "./TintMatchSearchListItem";

const TintMatchSearchList = ({ title, referenceHex, items }) =>
{
    return (
        <div className="tint-match-search-list">
            <Divider orientation="left" style={{ fontWeight: 600 }}><Tag>{title}</Tag></Divider>
            {referenceHex &&
                <>
                    <div className="tint-match-search-list-reference">
                        <div className="color" style={{ backgroundColor: referenceHex }} />
                        <div className="item-col">
                            <div className="item-title">Reference</div>
                            <div>{referenceHex}</div>
                        </div>
                    </div>
                    <Divider />
                </>
            }
            {items && items.length > 0 && items.map(item => <TintMatchSearchListItem key={item.handle} item={item} />)}
            {(!items || items.length === 0) && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={false} />}
        </div>
    );
};

export default TintMatchSearchList;

