import { Button, Space, Spin, Table, Tag, Tooltip } from 'antd'
import { useState } from 'react'
import TintAPI from '../utils/TintAPI'
import DataPagination from '../components/DataPagination'
import SearchInput from '../components/SearchInput'
import TooltipTitle from '../components/TooltipTitle'
import { PushpinOutlined } from '@ant-design/icons'
import './TintMatchColoursPanel.scss'
import ViewUtils from '../utils/ViewUtils'
import ColorPopover from '../components/ColorPopover'
import ColorantTags from '../components/ColorantTags'
import useTintAPI from '../hooks/useTintAPI'
import SummaryRow from '../components/SummaryRow'

const TintMatchColoursPanel = () => {
    const [search, setSearch] = useState(null)
    const [page, setPage] = useState(1)
    const [sort, setSort] = useState(null)
    const [data, loading] = useTintAPI(TintAPI.getDigitalColors, {
        search: search,
        page: page,
        sort: sort
    }, [search, page, sort])

    const processedData = data?.colors

    const onSearch = (search) => {
        setPage(1)
        setSearch(search)
    }
    const onTableChange = (pagination, filter, sorter) => {
        setSort({
            field: sorter.columnKey,
            dir: { ascend: 'asc', descend: 'desc' }[sorter.order]
        })
    }
    const onPageChange = (page) => {
        setPage(page)
    }


    const columns = [
        {
            dataIndex: 'hex',
            key: 'color',
            width: '40px',
            render: (hex, color) =>
                <ColorPopover color={color}>
                    <div style={{ backgroundColor: hex, borderRadius: 4, width: 40, height: 40 }} />
                </ColorPopover>,
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            sorter: (a, b) => 0,
            sortDirections: ['ascend', 'descend'],
            render: (name, row) => (
                <>
                    <div>{name}</div>
                    {row.aliases.map((alias, i) => <div className="subtitle" key={i}>{alias}</div>)}
                </>
            ),
        },
        {
            title: 'Brand',
            dataIndex: 'brand',
            key: 'brand',
            sorter: (a, b) => 0,
            sortDirections: ['ascend', 'descend'],
        },
        {
            title: 'Base',
            dataIndex: 'formula',
            key: 'base',
            render: formula => formula.base
        },
        {
            title: 'Formula',
            dataIndex: 'formula',
            key: 'formula',
            render: formula => <ColorantTags colorants={formula.colorants} />
        },
        {
            title: 'Approximate',
            dataIndex: 'approximate',
            key: 'approximate',
            sorter: (a, b) => 0,
            sortDirections: ['ascend', 'descend'],
            render: (flag) => (
                <Tag color={flag ? 'green' : null}>{flag === 1 && 'YES'}</Tag>
            ),
        },
        {
            title: 'Code',
            dataIndex: 'code',
            key: 'code',
            render: (code, color) =>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Tooltip placement="top" title="Copy"><Button onClick={() => ViewUtils.copyCodeToClipboard(code)}>{code}</Button></Tooltip>
                    <div className="pin-btn" onClick={() => ViewUtils.showColorPin(color)}><PushpinOutlined /></div>
                </div>
        },
    ]

    return (
        <div className="tint-match-colours-panel">
            <Space direction="vertical" size={64} style={{ width: '100%' }}>
                <SummaryRow items={[{ title: 'Total Tint Match Colours', value: processedData?.paging.total_item_count }]} loading={loading} />
                <TooltipTitle title="Tint Match Colours" tooltip="An exhaustive list of tintable colours. All custom colours map to the closest colour in this list." />
                <div>
                    <SearchInput loading={loading} onSearch={onSearch} />
                    <Spin spinning={loading}>
                        <Table columns={columns} dataSource={processedData?.items} rowKey={color => color.handle} pagination={false} onChange={onTableChange} />
                    </Spin>
                </div>
            </Space>
            <DataPagination paging={processedData?.paging} itemType="colours" onChange={onPageChange} />
        </div>
    )
}

export default TintMatchColoursPanel
