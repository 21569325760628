import { DatePicker } from 'antd'
import DateUtils from '../utils/DateUtils'
import moment from 'moment'

const { RangePicker } = DatePicker

const DateRangePicker = ({ defValue, onChange }) => {
    if (defValue) {
        defValue = [
            defValue[0] ? new moment(defValue[0] * 1000) : null,
            defValue[1] ? new moment(defValue[1] * 1000) : null
        ]
    }

    const onChangeInternal = range => {
        if (range)
            onChange([DateUtils.toDateEpoch(range[0], false), DateUtils.toDateEpoch(range[1], true)])
        else
            onChange([null, null])
    }

    return (
        <RangePicker defaultValue={defValue} allowEmpty={[true, true]} onChange={onChangeInternal} />
    )
}

export default DateRangePicker

