import { Line } from '@ant-design/charts'
import { useMemo, useState } from 'react'
import { Radio } from 'antd'
import AggregationUtils from '../../utils/AggregationUtils'
import TintAPI from '../../utils/TintAPI'

const MultiCohortChart = ({ cohorts, selectedCohortKey }) => {
    cohorts = cohorts || []

    const [displayType, setDisplayType] = useState('absolute')

    const maxWeeks = useMemo(() => {
        let max = 0

        for (const cohort of cohorts) {
            const weeks = Object.keys(cohort.convert_periods)
            if (Object.keys(cohort.convert_periods).length === 0) {
                continue
            }

            weeks.sort((a, b) => {
                const aInt = parseInt(a)
                const bInt = parseInt(b)

                if (aInt < bInt) return -1
                if (aInt > bInt) return 1
                return 0
            })

            max = Math.max(max, weeks[weeks.length - 1])
        }

        return max
    }, [cohorts])

    const processedData = useMemo(() => {
        const processedData = []

        for (const cohort of cohorts) {
            if (Object.keys(cohort.convert_periods).length === 0) {
                continue
            }

            if (selectedCohortKey && cohort.key !== selectedCohortKey) {
                continue
            }

            for (let week = 0; week <= maxWeeks; week++) {
                let converted = cohort.convert_periods.hasOwnProperty(week) ? cohort.convert_periods[week] : 0
                if (displayType === 'percentage') {
                    converted = Math.round(converted / cohort.converted * 100 * 100) / 100
                }

                processedData.push({ week, converted, cohort: cohort.key })
            }
        }

        return processedData
    }, [selectedCohortKey, maxWeeks, displayType])

    const config = useMemo(() => {
        const config = {
            data: processedData,
            xField: 'week',
            yField: 'converted',
            seriesField: 'cohort',
            xAxis: {
                title: {
                    text: 'Weeks from initial sample purchase'
                }
            },
            yAxis: {
                title: {
                    text: displayType === 'percentage' ? '% of cohort conversions' : 'Conversions'
                }
            },
            legend: {
                position: 'right'
            },
            slider: {
                start: 0,
                end: 20 / maxWeeks,
                height: 50,
                formatter: value => `${value} weeks`
            },
            meta: {
                cohort: {
                    formatter: value => AggregationUtils.getIntervalString(value, TintAPI.Analytics.Group.TWO_MONTH)
                },
                week: {
                    alias: 'Week',
                    formatter: (value) => `${value} weeks`
                },
                converted: {
                    alias: 'Converted',
                    formatter: (value) => displayType === 'percentage' ? `${value}%` : value
                }
            },
            annotations: []
        }

        if (displayType === 'percentage') {
            const totalConverted = cohorts.reduce((total, cohort) => total + cohort.converted, 0)
            const total = cohorts.reduce((total, cohort) => total + cohort.count, 0)
            const avgConverted = totalConverted / total * 100

            config.annotations = [
                {
                    type: 'text',
                    position: ['max', avgConverted],
                    content: `${Math.round(avgConverted)}% average conversion rate`,
                    offsetX: -150,
                    offsetY: -4,
                    style: { textBaseline: 'bottom' },
                },
                {
                    type: 'line',
                    start: ['min', avgConverted],
                    end: ['max', avgConverted],
                    style: {
                        stroke: '#000',
                        lineWidth: 2,
                        lineDash: [10, 5],
                    },
                },
            ]
        }

        return config
    }, [processedData])


    return (
        <>
            <Line {...config} style={{ height: 600 }} />
            <div style={{ marginTop: 24, display: 'flex' }}>
                <div style={{ flex: '1 1 0' }} />
                <div style={{ flex: '1 1 0' }}>
                    <Radio.Group defaultValue={displayType} style={{ textAlign: 'center', display: 'block' }} onChange={e => setDisplayType(e.target.value)}>
                        <Radio.Button value="absolute">Absolute</Radio.Button>
                        <Radio.Button value="percentage">Percentage</Radio.Button>
                    </Radio.Group>
                </div>
                <div style={{ flex: '1 1 0' }} />
            </div>
        </>
    )
}

export default MultiCohortChart

