import TooltipTitle from '../TooltipTitle'
import { getLocalSettings } from '../../redux/selectors'
import { connect } from 'react-redux'

const AggregateTitle = ({ title, tooltip, suffix, aggregation, aggregationKey, isMoney, localSettings }) => {
    aggregationKey = aggregationKey || 'count'
    const count = aggregation ? aggregation.reduce((total, item) => total + item[aggregationKey], 0) : 0
    const countStr = isMoney ? count.toMoneyString() : count.toLocaleString()

    return (
        <>
            <TooltipTitle title={title} tooltip={tooltip} />
            {localSettings.showSummaryCounts && aggregation && <div className="subtitle">{countStr} {suffix}</div>}
        </>
    )
}


const mapStateToProps = state => {
    const localSettings = getLocalSettings(state)
    return { localSettings }
}

export default connect(mapStateToProps)(AggregateTitle)
