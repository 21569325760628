import { Button, Tag } from "antd";
import ViewUtils from "../utils/ViewUtils";
import "./TintMatchSearchListItem.scss"
import { PushpinOutlined } from "@ant-design/icons";
import ColorUtils from "../utils/ColorUtils";
import ColorPopover from "./ColorPopover";

const TintMatchSearchListItem = ({ item }) =>
{
    let listItem = (
        <div className="tint-match-search-list-item">
            <ColorPopover color={item}>
                <div className="color pin-btn" style={{ backgroundColor: item.hex, position: 'relative' }} onClick={() => ViewUtils.showColorPin(item)}>
                    <PushpinOutlined className="pin-icon" style={{ color: ColorUtils.isDark(item.hex) ? 'white' : 'black' }} />
                </div>
            </ColorPopover>
            <div className="item-col">
                <div className="item-title">{item.brand}</div>
                <div>{item.name}</div>
                {item.promoted && <Tag color="green" style={{ lineHeight: 1, padding: '2px 4px' }}>Promoted</Tag> }
                {!item.promoted && <Button size="small" style={{ marginTop: 4 }} onClick={() => ViewUtils.copyCodeToClipboard(item.code)}>{item.code}</Button> }
            </div>
            {item.de &&
            <div className="item-col" style={{ fontSize: 24, lineHeight: 1, textAlign: 'right', flex: '1 1 0', alignSelf: 'flex-start' }}>
                <span className="subtitle" style={{ fontSize: 12, marginRight: 6 }}>&Delta;E</span>
                <span>{item.de.toFixed(1)}</span>
            </div>
            }
        </div>
    );

    return listItem;
};

export default TintMatchSearchListItem;

