import { Statistic, Tooltip } from 'antd'
import { QuestionCircleOutlined } from '@ant-design/icons'

const TooltipStatistic = ({ title, value, tooltip, loading }) => {
    const Title = (
        <>
            {title}<Tooltip title={tooltip}><QuestionCircleOutlined style={{ fontSize: '16px', color: '#888', marginLeft: '8px' }} /></Tooltip>
        </>
    )

    return (
        <Statistic title={Title} value={value} loading={loading} />
    )
};

export default TooltipStatistic

