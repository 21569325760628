import { Pagination } from 'antd';
import './DataPagination.scss';

const DataPagination = ({ paging, itemType, onChange }) =>
{
    return (
        <div className="data-pagination">
            <div className="total-items">{paging && `${paging.total_item_count.toLocaleString()} ${itemType}`}</div>
            {paging && paging.total_item_count > 0 && <Pagination current={paging ? paging.current_page : 0} pageSize={paging ? paging.items_per_page : 0} total={paging ? paging.total_item_count : 0} showSizeChanger={false} onChange={onChange}></Pagination>}
        </div>
    );
};

export default DataPagination;

