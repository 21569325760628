import { ADD_COLOR_PIN, REMOVE_COLOR_PIN } from "../actionTypes";

const reducer = (state = [], action) =>
{
    switch (action.type)
    {
        case ADD_COLOR_PIN:
        {
            if (state.find(color => color.handle === action.payload.handle))
                return state;

            return [
                ...state,
                {
                    handle: action.payload.handle,
                    name: action.payload.name,
                    brand: action.payload.brand,
                    code: action.payload.code,
                    hex: action.payload.hex,
                }
            ];
        }
        case REMOVE_COLOR_PIN:
            return state.filter(color => color.handle !== action.payload.handle);
        default:
            return state;
    }
}

export default reducer
