import { Space } from 'antd'
import TintAPI from '../../utils/TintAPI'
import StackedAggregateChart from '../../components/analytics/StackedAggregateChart'
import AggregationUtils from '../../utils/AggregationUtils'
import AggregateChart from '../../components/analytics/AggregateChart'
import useTintAPI from '../../hooks/useTintAPI'
import { useMemo, useState } from 'react'
import DateUtils from '../../utils/DateUtils'
import AggregateTitle from '../../components/analytics/AggregateTitle'

const OrdersAnalyticsPanel = () => {
    const [orderInterval, setOrderInterval] = useState(TintAPI.Analytics.Group.WEEK)
    const [orderDateRange, setOrderDateRange] = useState(DateUtils.defEpochRange())
    const [orderAggregation, orderLoading] = useTintAPI(TintAPI.getAnalyticsAggregation, {
        metrics: TintAPI.Analytics.Metrics.ORDER_COUNT,
        group: orderInterval,
        start: orderDateRange[0],
        end: orderDateRange[1]
    }, [orderInterval, orderDateRange])

    const [orderValueInterval, setOrderValueInterval] = useState(TintAPI.Analytics.Group.WEEK)
    const [orderValueDateRange, setOrderValueDateRange] = useState(DateUtils.defEpochRange())
    const [orderValueAggregation, orderValueLoading] = useTintAPI(TintAPI.getAnalyticsAggregation, {
        metrics: TintAPI.Analytics.Metrics.ORDER_VALUE,
        group: orderValueInterval,
        start: orderValueDateRange[0],
        end: orderValueDateRange[1]
    }, [orderValueInterval, orderValueDateRange])

    const processedAggregation = useMemo(() => {
        if (!orderAggregation) {
            return null
        }

        let processedAggregation = AggregationUtils.addMissingInterval(orderAggregation.aggregations[0].items, orderAggregation.aggregations[0].group)
        return AggregationUtils.addRunningTotals(processedAggregation)

    }, [orderAggregation])

    const orderStackedAggregation = useMemo(() => {
        if (!processedAggregation) {
            return null
        }

        return AggregationUtils.createStackedAggregation(processedAggregation, {
            sample_exclusive_count: 'Sample Only',
            not_sample_exclusive_count: 'Other'
        }, orderAggregation.aggregations[0].group)

    }, [processedAggregation])

    const processedOrderValueAggregation = useMemo(() => {
        if (!orderValueAggregation) {
            return null
        }

        let result = AggregationUtils.addMissingInterval(orderValueAggregation.aggregations[0].items, orderValueAggregation.aggregations[0].group)
        result = AggregationUtils.addRunningTotals(result)
        return result

    }, [orderValueAggregation])


    return (
        <>
            <Space direction="vertical" size={64} style={{ width: '100%' }}>
                <AggregateTitle title="Total Order" suffix="orders" aggregation={processedAggregation} />
                <StackedAggregateChart stackedAggregation={orderStackedAggregation} isLoading={orderLoading}
                                       defInterval={orderInterval} defDateRange={orderDateRange}
                                       intervalSelectorMode="extended" showPercentageToggle={true} showDateRangePicker={true}
                                       onIntervalChange={setOrderInterval} onDateRangeChange={setOrderDateRange} />

                <AggregateTitle title="Order Value" tooltip="Represents price of all the items in the order after both line-item and cart discounts have been applied. The subtotal doesn't include shipping costs."
                                suffix="value" aggregation={processedOrderValueAggregation} isMoney={true} />
                <AggregateChart aggregation={processedOrderValueAggregation} isLoading={orderValueLoading}
                                yType="money" defInterval={orderValueInterval} defDateRange={orderValueDateRange}
                                intervalSelectorMode="extended" showDateRangePicker={true}
                                onIntervalChange={setOrderValueInterval} onDateRangeChange={setOrderValueDateRange} />
            </Space>
        </>
    );
};

export default OrdersAnalyticsPanel;

