import moment from 'moment'
import './Extensions'
import TintAPI from './TintAPI'

const AggregationUtils = {
    addRunningTotals(list) {
        let total = 0

        return list.map(item => {
            total += item.count
            return {
                ...item,
                total: total
            }
        })
    },

    addProportions(list) {
        let total = list.reduce((total, item) => total + item.count, 0)

        return list.map(item => {
            return {
                ...item,
                proportion: item.count / total
            }
        })
    },

    addRollingPercent(list, countKey = 'count', totalKey = 'total', rollingKey = 'rolling_count') {
        const rollingCount = []
        const rollingTotal = []

        for (let i = 0; i < list.length; i++) {
            rollingCount.push(list[i][countKey] || 0)
            rollingTotal.push(list[i][totalKey])

            if (rollingTotal.length < 4) {
                continue
            }

            if (rollingTotal.length > 4) {
                rollingTotal.shift()
                rollingCount.shift()
            }

            const total = rollingTotal.sum()
            list[i][rollingKey] = total > 0 ? rollingCount.sum() / total * 100 : 0
        }

        return list
    },
    addRollingValue(list, countKey = 'count', rollingKey = 'rolling_count') {
        const rollingValue = []

        for (let i = 0; i < list.length; i++) {
            rollingValue.push(list[i][countKey])

            if (rollingValue.length < 4) {
                continue
            }

            if (rollingValue.length > 4) {
                rollingValue.shift()
            }

            list[i][rollingKey] = rollingValue.sum()
        }

        return list
    },

    addMissingWeeks(list) {
        return this.addMissingInterval(list, TintAPI.Analytics.Group.WEEK)
    },
    addMissingInterval(list, interval) {
        if ([TintAPI.Analytics.Group.HOUR_OF_DAY, TintAPI.Analytics.Group.DAY_OF_WEEK].includes(interval)) {
            return this.addMissingTimeframeInterval(list, interval)
        } else {
            return this.addMissingStandardInterval(list, interval)
        }
    },
    addMissingStandardInterval(list, interval) {
        list = list.sort((a, b) => a.key.localeCompare(b.key))

        for (let i = list.length - 1; i >= 1; i--) {
            let fillCount = 0

            while (true) {
                const dt = new moment(list[i].key)

                let expectedNextDt
                switch (interval) {
                    case TintAPI.Analytics.Group.DAY:
                        expectedNextDt = dt.subtract(1, 'day')
                        break
                    case TintAPI.Analytics.Group.WEEK:
                        expectedNextDt = dt.subtract(1, 'week')
                        break
                    case TintAPI.Analytics.Group.MONTH:
                        expectedNextDt = dt.subtract(1, 'month')
                        break
                    case TintAPI.Analytics.Group.TWO_MONTH:
                        expectedNextDt = dt.subtract(2, 'month')
                        break
                    default:
                        throw new Error('Unhandled group')
                }

                const nextDt = new moment(list[i - 1].key)
                let intervalCheck
                switch (interval) {
                    case TintAPI.Analytics.Group.DAY:
                    case TintAPI.Analytics.Group.WEEK:
                        intervalCheck = 'day'
                        break
                    case TintAPI.Analytics.Group.MONTH:
                    case TintAPI.Analytics.Group.TWO_MONTH:
                        intervalCheck = 'month'
                        break
                    default:
                        throw new Error('Unhandled group')
                }

                if (expectedNextDt.isSame(nextDt, intervalCheck)) {
                    break
                }

                list.splice(i, 0, {
                    key: expectedNextDt.format(interval === TintAPI.Analytics.Group.WEEK ? 'YYYY-MM-DD' : 'YYYY-MM-01'),
                    count: 0
                })

                fillCount++
                if (fillCount > 24) {
                    console.log('Infinite loop detected')
                    break
                }
            }
        }

        return list
    },
    addMissingTimeframeInterval(list, interval) {
        list = list.sort((a, b) => a.key.localeCompare(b.key))

        let min, max
        switch (interval) {
            case TintAPI.Analytics.Group.HOUR_OF_DAY:
                min = 0
                max = 23
                break
            case TintAPI.Analytics.Group.DAY_OF_WEEK:
                min = 1
                max = 7
                break
            default:
                throw new Error('Unhandled group')
        }

        const res = new Array(max - min + 1)

        for (const item of list) {
            res[parseInt(item.key) - min] = item
        }

        for (let i = 0; i < res.length; i++) {
            if (!res[i]) {
                res[i] = {
                    key: (min + i).toString().padStart(interval === TintAPI.Analytics.Group.HOUR_OF_DAY ? 2 : 1, '0'),
                    count: 0
                }
            }
        }

        return res
    },


    createStackedAggregation(aggregation, mapping, interval = TintAPI.Analytics.Group.WEEK) {
        const stackedAggregation = []
        aggregation = AggregationUtils.addMissingInterval(aggregation, interval)

        for (const item of aggregation) {
            for (const countKey in mapping) {
                stackedAggregation.push({
                    key: item.key,
                    count: item.hasOwnProperty(countKey) ? item[countKey] : 0,
                    type: mapping[countKey]
                })
            }
        }

        return stackedAggregation
    },

    createStackedAggregationFromMultiple(aggregations) {
        const aggregationHashes = []
        const dateHash = {}

        // create hashed version of aggregations
        for (const aggregation of aggregations) {
            const aggregationHash = {}

            for (let item of aggregation.aggregation) {
                aggregationHash[item.key] = item
                if (!dateHash.hasOwnProperty(item.key)) {
                    dateHash[item.key] = true
                }
            }

            aggregationHashes.push({
                title: aggregation.title,
                hash: aggregationHash
            })
        }

        // create aggregation with all dates
        let dateAggregation = Object.keys(dateHash).map(key => {
            return {
                key: key,
                stack: []
            }
        })
        dateAggregation = AggregationUtils.addMissingWeeks(dateAggregation)

        // create final aggregation
        const stackAggregation = []
        for (const stackItem of dateAggregation) {
            for (const hash of aggregationHashes) {
                stackAggregation.push({
                    key: stackItem.key,
                    count: hash.hash.hasOwnProperty(stackItem.key) ? hash.hash[stackItem.key].count : 0,
                    type: hash.title
                })
            }
        }

        return stackAggregation
    },

    createStackedAggregationFromMultipleNew(aggregations) {
        const aggregationHashes = []
        const dateHash = {}

        // create hashed version of aggregations
        for (const aggregation of aggregations) {
            const aggregationHash = {}

            for (let item of aggregation.items) {
                aggregationHash[item.key] = item
                if (!dateHash.hasOwnProperty(item.key)) {
                    dateHash[item.key] = true
                }
            }

            aggregationHashes.push({
                title: aggregation.metric.replaceAll('_', ' ').toTitleCase(),
                hash: aggregationHash
            })
        }

        // create aggregation with all dates
        let dateAggregation = Object.keys(dateHash).map(key => {
            return {
                key: key,
                stack: []
            }
        })
        dateAggregation = AggregationUtils.addMissingWeeks(dateAggregation)

        // create final aggregation
        const stackAggregation = []
        for (const stackItem of dateAggregation) {
            for (const hash of aggregationHashes) {
                stackAggregation.push({
                    key: stackItem.key,
                    count: hash.hash.hasOwnProperty(stackItem.key) ? hash.hash[stackItem.key].count : 0,
                    type: hash.title
                })
            }
        }

        return stackAggregation
    },


    getIntervalString (key, interval) {
        switch (interval) {
            case TintAPI.Analytics.Group.DAY:
            case TintAPI.Analytics.Group.WEEK:
                return new moment(key).format('DD-MMM-YY')
            case TintAPI.Analytics.Group.MONTH:
                return new moment(key).format('MMM-YY')
            case TintAPI.Analytics.Group.TWO_MONTH:
                const dt = new moment(key)
                return `${dt.format('MMMYY')} - ${dt.add(2, 'month').format('MMMYY')}`
            case TintAPI.Analytics.Group.HOUR_OF_DAY:
                const hr = parseInt(key)
                if (hr < 12) {
                    return `${hr === 0 ? 12 : hr}am`
                } else {
                    return `${hr === 12 ? 12 : hr - 12}pm`
                }
            case TintAPI.Analytics.Group.DAY_OF_WEEK:
                return ['', 'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'][parseInt(key)]
            default:
                return 'Unknown'
        }
    }
}

export default AggregationUtils
