import { Space } from 'antd'
import TintAPI from '../../utils/TintAPI'
import StackedAggregateChart from '../../components/analytics/StackedAggregateChart'
import AggregationUtils from '../../utils/AggregationUtils'
import useTintAPI from '../../hooks/useTintAPI'
import { useMemo, useState } from 'react'
import DateUtils from '../../utils/DateUtils'
import AggregateTitle from '../../components/analytics/AggregateTitle'

const LogtisticsDeliveryAnalyticsPanel = () => {
    const [feeInterval, setFeeInterval] = useState(TintAPI.Analytics.Group.WEEK)
    const [feeDateRange, setFeeDateRange] = useState(DateUtils.defEpochRange())
    const [feeAggregation, feeLoading] = useTintAPI(TintAPI.getAnalyticsAggregation, {
        metrics: TintAPI.Analytics.Metrics.ORDER_DELIVERY_FEE,
        group: feeInterval,
        start: feeDateRange[0],
        end: feeDateRange[1]
    }, [feeInterval, feeDateRange])

    const [methodInterval, setMethodInterval] = useState(TintAPI.Analytics.Group.WEEK)
    const [methodDateRange, setMethodDateRange] = useState(DateUtils.defEpochRange())
    const [methodAggregation, methodLoading] = useTintAPI(TintAPI.getAnalyticsAggregation, {
        metrics: TintAPI.Analytics.Metrics.ORDER_DELIVERY_METHOD,
        group: methodInterval,
        start: methodDateRange[0],
        end: methodDateRange[1]
    }, [methodInterval, methodDateRange])

    const processedFeeAggregation = useMemo(() => {
        if (!feeAggregation) {
            return null
        }

        let processedAggregation = AggregationUtils.addMissingInterval(feeAggregation.aggregations[0].items, feeAggregation.aggregations[0].group)
        return AggregationUtils.addRunningTotals(processedAggregation)

    }, [feeAggregation])

    const feeStackedAggregation = useMemo(() => {
        if (!feeAggregation) {
            return null
        }

        return AggregationUtils.createStackedAggregation(processedFeeAggregation, {
            express_price: 'Express',
            uber_price: 'Uber',
            other_price: 'Other'
        }, feeAggregation.aggregations[0].group)

    }, [processedFeeAggregation])

    const processedMethodAggregation = useMemo(() => {
        if (!methodAggregation) {
            return null
        }

        let processedAggregation = AggregationUtils.addMissingInterval(methodAggregation.aggregations[0].items, methodAggregation.aggregations[0].group)
        return AggregationUtils.addRunningTotals(processedAggregation)

    }, [methodAggregation])

    const methodStackedAggregation = useMemo(() => {
        if (!methodAggregation) {
            return null
        }

        return AggregationUtils.createStackedAggregation(processedMethodAggregation, {
            express_count: 'Express',
            express_free_count: 'Express (free)',
            uber_count: 'Uber',
            pickup_count: 'Pickup',
            other_count: 'Other',
            other_free_count: 'Other (free)'
        }, methodAggregation.aggregations[0].group)

    }, [processedMethodAggregation])


    return (
        <>
            <Space direction="vertical" size={64} style={{ width: '100%' }}>
                {false &&
                    <>
                        <AggregateTitle title="Delivery Fee" suffix="fees" aggregation={processedFeeAggregation} isMoney={true} />
                        <StackedAggregateChart stackedAggregation={feeStackedAggregation} columnType="money" isLoading={feeLoading}
                                               defInterval={feeInterval} defDateRange={feeDateRange}
                                               intervalSelectorMode="extended" showPercentageToggle={true} showDateRangePicker={true}
                                               onIntervalChange={setFeeInterval} onDateRangeChange={setFeeDateRange} />
                    </>
                }

                <AggregateTitle title="Delivery Method" suffix="deliveries" aggregation={processedMethodAggregation} />
                <StackedAggregateChart stackedAggregation={methodStackedAggregation} isLoading={methodLoading}
                                       defInterval={methodInterval} defDateRange={methodDateRange}
                                       intervalSelectorMode="extended" showPercentageToggle={true} showDateRangePicker={true}
                                       onIntervalChange={setMethodInterval} onDateRangeChange={setMethodDateRange} />
            </Space>
        </>
    );
};

export default LogtisticsDeliveryAnalyticsPanel;

