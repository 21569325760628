import { Descriptions, Divider, Popover, Spin, Typography } from 'antd';
import './ColorPopover.scss'
import { useState } from "react";
import ColorUtils from "../utils/ColorUtils";
import TintAPI from "../utils/TintAPI";
import { getUser } from "../redux/selectors";
import { connect } from "react-redux";
import ViewUtils from "../utils/ViewUtils";
import { PushpinOutlined } from "@ant-design/icons";
import Text from "antd/lib/typography/Text";
const { Link } = Typography;

const ColorPopover = ({ user, children, color }) =>
{
    const [similarColors, setSimilarColors] = useState(null);
    const [dataLoading, setDataLoading] = useState(true);

    const loadData = async () =>
    {
        setDataLoading(true);

        try
        {
            const data = await TintAPI.getDigitalColorMatches(user.accessToken, color.code, 5, true);

            // promote tint brand if reference colour does not match it
            if (color.handle !== data.matches[0].handle)
                setSimilarColors([data.matches[0], ...data.matches.slice(3)]);
            else
                setSimilarColors(data.matches.slice(2));
        }
        catch (e)
        {
        }

        setDataLoading(false);
    };

    const onVisibleChange = async (visible) =>
    {
        if (visible && !similarColors)
            await loadData();
    };

    const rgb = ColorUtils.hexToRgb(color.hex);

    const similarColor = color => {
        return <div className="similar-color" key={color.handle}>
            <div className="color" style={{ backgroundColor: color.hex }} onClick={() => ViewUtils.showColorPin(color)}>
                <PushpinOutlined className="pin-icon" style={{ color: ColorUtils.isDark(color.hex) ? 'white' : 'black' }} />
            </div>
            <div className="item-col">
                <div className="color-title">{color.brand}</div>
                <div className="color-subtitle">{color.name}</div>
                {color.promoted && <Text type="success" style={{ fontSize: 12, lineHeight: 1 }}>Promoted</Text> }
                {!color.promoted && <Link className="color-code" onClick={() => ViewUtils.copyCodeToClipboard(color.code)}>{color.code}</Link> }
            </div>
            {color.de &&
                <div className="item-col" style={{ fontSize: 18, lineHeight: 1, textAlign: 'right', flex: '1 1 0' }}>
                    <span className="subtitle" style={{ fontSize: 10, marginRight: 6 }}>&Delta;E</span>
                    <span>{color.de.toFixed(1)}</span>
                </div>
            }
        </div>
    };

    const content = (
        <div className="color-popover" key={color.handle}>
            <div className="name-row">
                <div className="color" style={{ backgroundColor: color.hex }} />
                <div className="name-col">
                    <div className="name-title">{color.brand}</div>
                    <div className="name-subtitle">{color.name}</div>
                </div>
            </div>
            <Descriptions size="small" column={1} bordered style={{ marginTop: 12 }} contentStyle={{ textAlign: 'center', padding: 0 }}>
                <Descriptions.Item label="HEX">{color.hex}</Descriptions.Item>
                <Descriptions.Item label="RGB">
                    <div className="color-values">
                        <div>{rgb[0]}</div>
                        <div>{rgb[1]}</div>
                        <div>{rgb[2]}</div>
                    </div>
                </Descriptions.Item>
                { color.lab &&
                    <Descriptions.Item label="LAB">
                        <div className="color-values">
                            <div>{color.lab.l.toFixed(2)}</div>
                            <div>{color.lab.a.toFixed(2)}</div>
                            <div>{color.lab.b.toFixed(2)}</div>
                        </div>
                    </Descriptions.Item>
                }
            </Descriptions>
            <Divider orientation="left" style={{ fontSize: 14, fontWeight: 600 }}>Similar colours</Divider>
            <div style={{ minHeight: 178, padding: dataLoading ? '24px 0 40px' : 0 }}>
                <Spin spinning={dataLoading}>
                    {similarColors && similarColors.map(color => similarColor(color))}
                </Spin>
            </div>
        </div>
    );

    return (
        <Popover placement="left" content={content} onVisibleChange={onVisibleChange}>{children}</Popover>
    );
};

const mapStateToProps = state =>
{
    const user = getUser(state);
    return { user };
};

export default connect(mapStateToProps)(ColorPopover);
