import { Form, Input, Button, Alert, Space } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import TintAPI from "../utils/TintAPI";
import "./LoginForm.scss"
import { connect } from "react-redux";
import { setUser } from "../redux/actions";
import { getUser } from "../redux/selectors";
import { Redirect } from "react-router-dom";
import { useState } from "react";

const LoginForm = ({ user, setUser }) =>
{
    const [stage, setStage] = useState(1);
    const [authFlowToken, setAuthFlowToken] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    if (user)
        return (<Redirect to="/" />)

    const validateMessages = {
        // eslint-disable-next-line
        required: '${name} is required',
        types: {
            email: 'Invalid email',
        },
    };


    const onStage1Finish = async (values) =>
    {
        setLoading(true);

        try
        {
            const data = await TintAPI.signInPassword(values.email, values.password);
            setAuthFlowToken(data.authFlowToken);
            setStage(2);
            setError(null)
        }
        catch (e)
        {
            let error = 'Something went really wrong';
            switch (e.code)
            {
                case TintAPI.ErrorCode.NOT_AUTHED:
                    error = 'Invalid credentials';
                    break;
                case TintAPI.ErrorCode.AUTH_GATEWAY:
                    error = 'Something went wrong';
                    break;
            }

            setError(error);
        }

        setLoading(false);
    };

    const onStage2Finish = async (values) =>
    {
        setLoading(true);

        try
        {
            const user = await TintAPI.signInCode(authFlowToken, values.code);
            setUser(user);
        }
        catch (e)
        {
            let error = 'Something went wrong';
            switch (e.code)
            {
                case TintAPI.ErrorCode.NOT_AUTHED:
                    error = 'Invalid code';
                    break;
            }

            setError(error);
        }

        setLoading(false);
    };

    const onBackToSignInClick = () =>
    {
        setStage(1);
        setAuthFlowToken(null);
        setLoading(false);
        setError(null);
    };


    return (
        <div className="login-form">
            {stage === 1 &&
                <Space direction="vertical" size="large" style={{width: '100%'}}>
                    {error && <Alert message={error} type="error" />}
                    <Form validateMessages={validateMessages} onFinish={onStage1Finish}>
                        <Form.Item name="email" rules={[{ required: true, type: 'email' }]}>
                            <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Email" />
                        </Form.Item>
                        <Form.Item name="password" rules={[{ required: true }]}>
                            <Input.Password prefix={<LockOutlined className="site-form-item-icon" />} placeholder="Password" />
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary" htmlType="submit" loading={loading}>Login</Button>
                        </Form.Item>
                    </Form>
                </Space>
            }
            {stage === 2 &&
                <Space direction="vertical" size="large" style={{width: '100%'}}>
                    <div style={{ color: '#555' }}>Please enter the one time code sent to your email.</div>
                    {error && <Alert message={error} type="error" />}
                    <Form validateMessages={validateMessages} onFinish={onStage2Finish}>
                        <Form.Item name="code" rules={[{ required: true }]}>
                            <Input prefix={<LockOutlined className="site-form-item-icon" />} placeholder="Code" />
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary" htmlType="submit" loading={loading}>Login</Button><Button type="link" onClick={onBackToSignInClick}>Back</Button>
                        </Form.Item>
                    </Form>
                </Space>
            }
        </div>
    );
};

const mapStateToProps = state =>
{
    const user = getUser(state);
    return { user };
};

export default connect(mapStateToProps, { setUser })(LoginForm);
