import { Redirect } from "react-router-dom";
import { getUser } from "../redux/selectors";
import { useEffect } from "react";
import { connect } from "react-redux";
import { setUser } from "../redux/actions";
import TintAPI from "../utils/TintAPI";

const LogoutPage = ({ user, setUser }) =>
{
    useEffect(() =>
    {
        logout();

        // eslint-disable-next-line
    }, []);

    const logout = async () =>
    {
        try
        {
            await TintAPI.signOut(user.accessToken);
        }
        catch (e)
        {
        }

        setUser(null);
    };

    document.title = 'Logout';

    return (<Redirect to="/login" />);
};

const mapStateToProps = state =>
{
    const user = getUser(state);
    return { user };
};

export default connect(mapStateToProps, { setUser })(LogoutPage);
