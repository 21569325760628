import { Radio } from 'antd'
import { useState, useEffect, useRef, useMemo } from 'react'
import LegendView from './LegendView'
import './Map.scss'
import moment from 'moment'
import mapboxgl from 'mapbox-gl/dist/mapbox-gl-csp'
// eslint-disable-next-line import/no-webpack-loader-syntax
import MapboxWorker from 'worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker'
import DateUtils from '../../utils/DateUtils'
import DateRangePicker from '../DateRangePicker'

const Map = ({ data, metaData, getClassification, markerDisplayMode, onDateRangeChange }) => {
    markerDisplayMode = markerDisplayMode || 'default'

    const MARKER_DISPLAY_COLOR = 'color'
    const SOURCE = 'source'
    const DISPLAY_TYPE_CLUSTERED = 'clustered'
    const DISPLAY_TYPE_UNCLUSTERED = 'unclustered'

    mapboxgl.workerClass = MapboxWorker
    mapboxgl.accessToken = 'pk.eyJ1IjoicmljaGFyZC1wYWxldHRlIiwiYSI6ImNrbHN2ajl2ZTBoeDgybmxkeGhqc212c3gifQ.JgMjc5M_c3LxC2mC609Ryg'
    // mapboxgl.accessToken = 'pk.eyJ1IjoicmljaGFyZC1wYWxldHRlIiwiYSI6ImNqeDE2cTA2bDAxcTA0OW84NzdxbGlxemgifQ.4KRX9kYetw1BdLdYnlxZOw';

    const mapContainer = useRef()
    const clustersActive = useRef(true)
    const clusterMarkers = useRef({})
    const clusterMarkersOnScreen = useRef({})

    const [firstRun, setFirstRun] = useState(true)
    const [map, setMap] = useState(null)
    const [activeLegendItems, setActiveLegendItems] = useState(metaData)
    const [dateRange, setDateRange] = useState(DateUtils.defEpochRange())
    const [displayType, setDisplayType] = useState(DISPLAY_TYPE_CLUSTERED)

    const mapDateRange = useMemo(() => {
        return [
            dateRange[0] ? moment(dateRange[0] * 1000) : null,
            dateRange[1] ? moment(dateRange[1] * 1000) : null
        ]
    }, [dateRange])


    useEffect(() => {
        clustersActive.current = displayType === DISPLAY_TYPE_CLUSTERED

        if (firstRun) {
            loadMap()
            setFirstRun(false)
        } else {
            if (data && map) {
                updateMapData()
            }
        }

        // eslint-disable-next-line
    }, [data, map, activeLegendItems, mapDateRange, displayType])


    const loadMap = () => {
        const map = new mapboxgl.Map({
            container: mapContainer.current,
            style: 'mapbox://styles/mapbox/light-v10',
            // style: 'mapbox://styles/richard-palette/cklwbtntp0knu17mlz4xe707n',
            center: [133.7751, -28.2744],
            zoom: 3.5
        })
        map.on('load', () => setMap(map))
        map.on('render', () => updateClusterMarkers(map))
    }

    const updateMapData = () => {
        for (const id in clusterMarkersOnScreen.current) {
            clusterMarkersOnScreen.current[id].remove()
        }

        clusterMarkers.current = {}
        clusterMarkersOnScreen.current = {}

        if (map.getSource(SOURCE)) {
            // map.removeLayer('clusters');
            // map.removeLayer('cluster-count');
            map.removeLayer('unclustered-point')
            map.removeSource(SOURCE)
        }

        const filteredGeojson = { ...geojson }
        filteredGeojson.features = filteredGeojson.features.filter(feature => {
            if (!activeLegendItems.find(item => item.id === feature.properties.classification)) {
                return false
            }
            return isWithinDateRange(feature)
        })

        const clusterProperties = {}
        for (const item of metaData) {
            clusterProperties[item.id] = ['+', ['case', ['==', ['get', 'classification'], item.id], 1, 0]]
        }

        map.addSource(SOURCE, {
            type: 'geojson',
            data: filteredGeojson,
            cluster: displayType === DISPLAY_TYPE_CLUSTERED,
            clusterMaxZoom: 12,
            clusterRadius: 50,
            clusterProperties: clusterProperties
        })

        // map.addLayer({
        //     id: 'clusters',
        //     type: 'circle',
        //     source: SOURCE,
        //     filter: ['has', 'point_count'],
        //     paint: {
        //         'circle-color': [
        //             'step', ['get', 'point_count'],
        //             '#222', 100,
        //             '#111', 750,
        //             '#000'
        //         ],
        //         'circle-radius': [
        //             'step',
        //             ['get', 'point_count'],
        //             20, 100,
        //             30, 750,
        //             40
        //         ]
        //     }
        // });
        // map.addLayer({
        //     id: 'cluster-count',
        //     type: 'symbol',
        //     source: SOURCE,
        //     filter: ['has', 'point_count'],
        //     layout: {
        //         'text-field': '{point_count_abbreviated}',
        //         'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
        //         'text-size': [
        //             'step',
        //             ['get', 'point_count'],
        //             14, 100,
        //             18, 750,
        //             24
        //         ],
        //     },
        //     paint: {
        //         'text-color': '#fff',
        //     }
        // });

        const circleColorExpr = ['match', ['get', 'classification']]
        const circleStrokeColorExpr = ['match', ['get', 'classification']]

        for (const item of metaData) {
            circleColorExpr.push(item.id)
            circleColorExpr.push(item.colors[0])
            circleStrokeColorExpr.push(item.id)
            circleStrokeColorExpr.push(item.colors[1])
        }

        circleColorExpr.push(metaData[metaData.length - 1].colors[0])
        circleStrokeColorExpr.push(metaData[metaData.length - 1].colors[1])

        map.addLayer({
            id: 'unclustered-point',
            type: 'circle',
            source: SOURCE,
            filter: ['!', ['has', 'point_count']],
            paint: {
                'circle-color': markerDisplayMode === MARKER_DISPLAY_COLOR ? ['get', 'color'] : circleColorExpr,
                'circle-radius': 5,
                'circle-stroke-width': 1,
                'circle-stroke-color': markerDisplayMode === MARKER_DISPLAY_COLOR ? '#888' : circleStrokeColorExpr
            }
        })
    }

    const updateClusterMarkers = (map) => {
        if (!clustersActive.current) {
            return
        }

        const newMarkers = {}
        const features = map.querySourceFeatures(SOURCE)

        for (let i = 0; i < features.length; i++) {
            const coords = features[i].geometry.coordinates
            const props = features[i].properties

            if (!props.cluster) {
                continue
            }

            const id = props.cluster_id

            let marker = clusterMarkers.current[id]
            if (!marker) {
                const el = createDonutChart(props)
                marker = clusterMarkers.current[id] = new mapboxgl.Marker({ element: el }).setLngLat(coords)
            }

            newMarkers[id] = marker

            if (!clusterMarkersOnScreen.current[id]) {
                marker.addTo(map)
            }
        }

        for (const id in clusterMarkersOnScreen.current) {
            if (!newMarkers[id]) {
                clusterMarkersOnScreen.current[id].remove()
            }
        }

        clusterMarkersOnScreen.current = newMarkers
    }
    const createDonutChart = (props) => {
        const offsets = []
        const counts = []
        let total = 0

        for (const item of metaData) {
            const count = props[item.id]
            counts.push(count)
            offsets.push(total)
            total += count
        }

        const fontSize = total >= 4500 ? 14 : 12
        const r = Math.min(Math.round(Math.sqrt((1200 + total) / Math.PI)), 50)
        const r0 = Math.round(r * 0.6)
        const w = r * 2

        let html = `<div><svg width="${w}" height="${w}" viewbox="0 0 ${w} ${w}" text-anchor="middle" style="font: ${fontSize}px Source Sans Pro; display: block">`

        for (let i = 0; i < counts.length; i++) {
            html += donutSegment(offsets[i] / total, (offsets[i] + counts[i]) / total, r, r0, metaData[i].colors[0])
        }

        html += `<circle cx="${r}" cy="${r}" r="${r0}" fill="black" /><text dominant-baseline="central" transform="translate(${r}, ${r})" style="fill: white">${total.toLocaleString()}</text></svg></div>`

        const el = document.createElement('div')
        el.innerHTML = html
        return el.firstChild
    }
    const donutSegment = (start, end, r, r0, color) => {
        if (end - start === 1) {
            end -= 0.00001
        }

        const a0 = 2 * Math.PI * (start - 0.25)
        const a1 = 2 * Math.PI * (end - 0.25)
        const x0 = Math.cos(a0), y0 = Math.sin(a0)
        const x1 = Math.cos(a1), y1 = Math.sin(a1)
        const largeArc = end - start > 0.5 ? 1 : 0

        return [
            '<path d="M', r + r0 * x0, r + r0 * y0,
            'L', r + r * x0, r + r * y0,
            'A', r, r, 0, largeArc, 1, r + r * x1, r + r * y1,
            'L', r + r0 * x1, r + r0 * y1,
            'A', r0, r0, 0, largeArc, 0, r + r0 * x0, r + r0 * y0,
            '" fill="' + color + '" />'
        ].join(' ')
    }


    const scatterCoord = coord => {
        const dist = Math.random() * 0.00001
        const angle = Math.random() * 2 * Math.PI

        coord.lng += dist * Math.cos(angle)
        coord.lat += dist * Math.sin(angle)

        return coord
    }

    const isWithinDateRange = feature => {
        if (mapDateRange[0] && feature.properties.date.isBefore(mapDateRange[0])) {
            return false
        }
        if (mapDateRange[1] && feature.properties.date.isAfter(mapDateRange[1])) {
            return false
        }
        return true
    }


    const geojson = useMemo(() => {
        if (!data) {
            return null
        }

        return {
            type: 'FeatureCollection',
            features: data.map(feature => {
                const coord = scatterCoord(feature.location)

                return {
                    properties: {
                        date: moment(feature.date),
                        classification: getClassification(feature),
                        color: feature.color
                    },
                    geometry: {
                        type: 'Point',
                        coordinates: [
                            coord.lng,
                            coord.lat
                        ]
                    }
                }
            })
        }
    }, [data])

    const processedMetaData = useMemo(() => {
        if (!geojson) {
            return metaData
        }

        const processed = []

        for (const item of metaData) {
            const filteredGeojson = geojson.features.filter(feature => {
                if (feature.properties.classification !== item.id) {
                    return false
                }
                return isWithinDateRange(feature)
            })
            processed.push({ ...item, count: filteredGeojson.length })
        }

        return processed
    }, [geojson, mapDateRange])


    const onDateRangeChangeInternal = range => {
        setDateRange(range)
        if (onDateRangeChange) {
            onDateRangeChange(range)
        }
    }


    return (
        <div className="map">
            <div style={{ marginBottom: 8, display: 'flex' }}>
                <LegendView items={processedMetaData} style={{ flex: '1 1 0' }} onActiveChange={activeItems => setActiveLegendItems(activeItems)}/>
                <DateRangePicker defValue={dateRange} onChange={onDateRangeChangeInternal} />
            </div>
            <div ref={mapContainer} style={{ height: 700 }}/>
            <Radio.Group defaultValue={displayType} style={{ textAlign: 'center', marginTop: 24, display: 'block' }} onChange={e => setDisplayType(e.target.value)}>
                <Radio.Button value={DISPLAY_TYPE_CLUSTERED}>Clustered</Radio.Button>
                <Radio.Button value={DISPLAY_TYPE_UNCLUSTERED}>Unclustered</Radio.Button>
            </Radio.Group>
        </div>
    )
}

export default Map

