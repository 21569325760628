import { Space } from 'antd'
import TintAPI from '../../utils/TintAPI'
import StackedAggregateChart from '../../components/analytics/StackedAggregateChart'
import AggregationUtils from '../../utils/AggregationUtils'
import useTintAPI from '../../hooks/useTintAPI'
import { useMemo, useState } from 'react'
import DateUtils from '../../utils/DateUtils'
import AggregateTitle from '../../components/analytics/AggregateTitle'
import UnfulfilledChart from '../../components/analytics/UnfulfilledChart'

const LogisticsDispatchAnalyticsPanel = () => {
    const [unfulfilledAggregation, unfulfilledLoading] = useTintAPI(TintAPI.getAnalyticsAggregation, {
        metrics: TintAPI.Analytics.Metrics.ORDER_DISPATCH_UNFULFILLED,
        group: TintAPI.Analytics.Group.WEEK
    }, [])

    const [paintInterval, setPaintInterval] = useState(TintAPI.Analytics.Group.WEEK)
    const [paintDateRange, setPaintDateRange] = useState(DateUtils.defEpochRange())
    const [paintAggregation, paintLoading] = useTintAPI(TintAPI.getAnalyticsAggregation, {
        metrics: TintAPI.Analytics.Metrics.ORDER_DISPATCH_PAINT_TIME,
        group: paintInterval,
        start: paintDateRange[0],
        end: paintDateRange[1]
    }, [paintInterval, paintDateRange])

    const [nonPaintInterval, setNonPaintInterval] = useState(TintAPI.Analytics.Group.WEEK)
    const [nonPaintDateRange, setNonPaintDateRange] = useState(DateUtils.defEpochRange())
    const [nonPaintAggregation, nonPaintLoading] = useTintAPI(TintAPI.getAnalyticsAggregation, {
        metrics: TintAPI.Analytics.Metrics.ORDER_DISPATCH_NON_PAINT_TIME,
        group: nonPaintInterval,
        start: nonPaintDateRange[0],
        end: nonPaintDateRange[1]
    }, [nonPaintInterval, nonPaintDateRange])


    const processedPaintAggregation = useMemo(() => {
        if (!paintAggregation) {
            return null
        }

        let processedAggregation = AggregationUtils.addMissingInterval(paintAggregation.aggregations[0].items, paintAggregation.aggregations[0].group)
        processedAggregation = AggregationUtils.addRollingPercent(processedAggregation, 'same_day_dispatch_count', 'count', 'rolling_avg')
        return AggregationUtils.addRunningTotals(processedAggregation)

    }, [paintAggregation])

    const stackedPaintAggregation = useMemo(() => {
        if (!processedPaintAggregation) {
            return null
        }

        return AggregationUtils.createStackedAggregation(processedPaintAggregation, {
            other_count: 'Other',
            next_day_dispatch_count: 'Next day dispatch',
            same_day_dispatch_count: 'Same day dispatch'
        }, paintAggregation.aggregations[0].group)

    }, [processedPaintAggregation])

    const processedNonPaintAggregation = useMemo(() => {
        if (!nonPaintAggregation) {
            return null
        }

        let processedAggregation = AggregationUtils.addMissingInterval(nonPaintAggregation.aggregations[0].items, nonPaintAggregation.aggregations[0].group)
        processedAggregation = AggregationUtils.addRollingPercent(processedAggregation, 'same_day_dispatch_count', 'count', 'rolling_avg')
        return AggregationUtils.addRunningTotals(processedAggregation)

    }, [nonPaintAggregation])

    const stackedNonPaintAggregation = useMemo(() => {
        if (!processedNonPaintAggregation) {
            return null
        }

        return AggregationUtils.createStackedAggregation(processedNonPaintAggregation, {
            other_count: 'Other',
            next_day_dispatch_count: 'Next day dispatch',
            same_day_dispatch_count: 'Same day dispatch'
        }, nonPaintAggregation.aggregations[0].group)

    }, [processedNonPaintAggregation])

    const columnColors = ref => {
        switch (ref.type) {
            case 'Same day dispatch':
                return '#73d13d'
            case 'Next day dispatch':
                return '#ffa940';
            case 'Other':
                return '#ff4d4f';
        }
    };


    return (
        <>
            <Space direction="vertical" size={64} style={{ width: '100%' }}>
                <AggregateTitle title="Unfulfilled Orders" tooltip="Shows number of unfulfilled orders at end of each day." />
                <UnfulfilledChart aggregation={unfulfilledAggregation} isLoading={unfulfilledLoading} />

                <AggregateTitle title="Paint Dispatch Time" tooltip="An order created after 3pm and dispatched before end of next business day is treated as same day dispatch. Orders with no dispatch event are ignored."
                                suffix="dispatches" aggregation={processedPaintAggregation} />
                <StackedAggregateChart stackedAggregation={stackedPaintAggregation} lineAggregation={processedPaintAggregation} isLoading={paintLoading}
                                       columnColors={columnColors} lineId="rolling_avg" lineType="percent" lineTitle="Same day dispatch 4-pt avg"
                                       defInterval={paintInterval} defDateRange={paintDateRange}
                                       intervalSelectorMode="extended" showPercentageToggle={true} showDateRangePicker={true}
                                       onIntervalChange={setPaintInterval} onDateRangeChange={setPaintDateRange} />

                <AggregateTitle title="Non-Paint Dispatch Time" tooltip="An order created after 3pm and dispatched before end of next business day is treated as same day dispatch. Orders with no dispatch event are ignored."
                                suffix="dispatches" aggregation={processedNonPaintAggregation} />
                <StackedAggregateChart stackedAggregation={stackedNonPaintAggregation} lineAggregation={processedNonPaintAggregation} isLoading={nonPaintLoading}
                                       columnColors={columnColors} lineId="rolling_avg" lineType="percent" lineTitle="Same day dispatch 4-pt avg"
                                       defInterval={nonPaintInterval} defDateRange={nonPaintDateRange}
                                       intervalSelectorMode="extended" showPercentageToggle={true} showDateRangePicker={true}
                                       onIntervalChange={setNonPaintInterval} onDateRangeChange={setNonPaintDateRange} />
            </Space>
        </>
    );
};

export default LogisticsDispatchAnalyticsPanel;

