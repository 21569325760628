import { Dropdown, Layout, Menu } from 'antd'
import { CaretDownOutlined } from '@ant-design/icons'
import './DashboardHeader.scss'
import { connect } from 'react-redux'
import { getUser } from '../redux/selectors'
import { Link, useHistory } from 'react-router-dom'

const { Header } = Layout

const DashboardHeader = ({ user }) => {
    const history = useHistory()

    const onMenuItemClick = ({ key }) => {
        switch (key) {
            case 'local-settings':
                history.push('/local-settings')
                break
            case 'logout':
                history.push('/logout')
                break
        }
    }

    const TheMenu = (
        <Menu onClick={onMenuItemClick}>
            <Menu.Item key="local-settings">Local settings</Menu.Item>
            <Menu.Item key="logout">Logout</Menu.Item>
        </Menu>
    )

    return (
        <Header className="dashboard-header">
            <div className="title-container">
                <Link className="title" to="/"><img className="logo" src="/logo192.png"  alt="Logo" />Mix Dashboard</Link>
            </div>
            {user &&
                <Dropdown overlay={TheMenu}>
                    <div className="ant-dropdown-link" onClick={e => e.preventDefault()}>{user.email}<CaretDownOutlined /></div>
                </Dropdown>
            }
        </Header>
    )
}


const mapStateToProps = state => {
    const user = getUser(state)
    return { user }
}

export default connect(mapStateToProps)(DashboardHeader)
