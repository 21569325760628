import { Space } from 'antd'
import TintAPI from '../../../utils/TintAPI'
import AggregateChart from '../../../components/analytics/AggregateChart'
import useTintAPI from '../../../hooks/useTintAPI'
import { useMemo, useState } from 'react'
import AggregationUtils from '../../../utils/AggregationUtils'
import DateUtils from '../../../utils/DateUtils'
import AggregateTitle from '../../../components/analytics/AggregateTitle'

const AccountsTintProAnalyticsSection = () => {
    const [interval, setInterval] = useState(TintAPI.Analytics.Group.WEEK)
    const [dateRange, setDateRange] = useState(DateUtils.defEpochRange())
    const [aggregation, loading] = useTintAPI(TintAPI.getAnalyticsAggregation, {
        metrics: TintAPI.Analytics.Metrics.TINT_PRO_APPLICATION_COUNT,
        group: interval,
        start: dateRange[0],
        end: dateRange[1]
    }, [interval, dateRange])

    const processedAggregation = useMemo(() => {
        if (!aggregation) {
            return null
        }

        let result = AggregationUtils.addMissingInterval(aggregation.aggregations[0].items, aggregation.aggregations[0].group)
        result = AggregationUtils.addRunningTotals(result)
        return result

    }, [aggregation])


    return (
        <>
            <Space direction="vertical" size={64} style={{ width: '100%' }}>
                <AggregateTitle title="Tint Pro Signups" tooltip="A list of all Tint Pro applications submitted via the website. Does not represent all Tint Pro users since it does not capture those who have become Tint Pro users via other means."
                                suffix="signups" aggregation={processedAggregation} />
                <AggregateChart aggregation={processedAggregation} isLoading={loading}
                                defInterval={interval} defDateRange={dateRange}
                                intervalSelectorMode="basic" showDateRangePicker={true}
                                onIntervalChange={setInterval} onDateRangeChange={setDateRange} />
            </Space>
        </>
    )
}

export default AccountsTintProAnalyticsSection

