import { ADD_COLOR_PIN, REMOVE_COLOR_PIN, SET_USER, APPLY_LOCAL_SETTINGS, APPLY_UI_SETTINGS } from './actionTypes'

export const setUser = user => ({
    type: SET_USER,
    payload: user
})

export const addColorPin = color => ({
    type: ADD_COLOR_PIN,
    payload: color
})

export const removeColorPin = color => ({
    type: REMOVE_COLOR_PIN,
    payload: color
})

export const applyLocalSettings = settings => ({
    type: APPLY_LOCAL_SETTINGS,
    payload: settings
})

export const applyUISettings = settings => ({
    type: APPLY_UI_SETTINGS,
    payload: settings
})
