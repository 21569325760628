import { Col, Row, Statistic } from 'antd'
import TooltipStatistic from './TooltipStatistic'
import { getLocalSettings } from '../redux/selectors'
import { connect } from 'react-redux'

const SummaryRow = ({ items, loading, localSettings }) => {
    if (!localSettings.showSummaryCounts) {
        return <></>
    }

    return (
        <Row gutter={16}>
            {items.map(item =>
                <Col key={item.title} span={4}>
                    {item.tooltip && <TooltipStatistic title={item.title} value={item.value ? item.value : '-'} tooltip={item.tooltip} loading={loading} />}
                    {!item.tooltip && <Statistic title={item.title} value={item.value ? item.value : '-'} loading={loading} />}
                </Col>
            )}
        </Row>
    )
}


const mapStateToProps = state => {
    const localSettings = getLocalSettings(state)
    return { localSettings }
}

export default connect(mapStateToProps)(SummaryRow)
