import { Space, Spin, Table, Tag } from 'antd'
import { useState } from 'react'
import TintAPI from '../utils/TintAPI'
import DataPagination from '../components/DataPagination'
import moment from 'moment'
import SearchInput from '../components/SearchInput'
import useTintAPI from '../hooks/useTintAPI'
import SummaryRow from '../components/SummaryRow'

const GuestUsersPanel = () => {
    const [search, setSearch] = useState(null)
    const [page, setPage] = useState(1)
    const [data, loading] = useTintAPI(TintAPI.getGuestUsers, {
        search: search,
        page: page
    }, [search, page])

    const processedData = data?.guest_users

    const onSearch = (search) => {
        setPage(1)
        setSearch(search)
    }
    const onPageChange = (page) => {
        setPage(page)
    }


    const columns = [
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Has Previously Subscribed',
            dataIndex: 'has_previously_subscribed',
            key: 'has_previously_subscribed',
            render: (flag) => (
                <Tag color={flag ? 'green' : null}>{flag && 'YES'}</Tag>
            ),
        },
        {
            title: 'Colour Club',
            dataIndex: 'color_club_sign_up',
            key: 'color_club_sign_up',
            render: (obj) => (
                <div className="subtitle">
                    {obj && Object.keys(obj).map((key) => <div key={key}>{key}: {obj[key]}</div>)}
                </div>
            ),
        },
        {
            title: 'Acquired',
            dataIndex: 'created_at',
            key: 'created_at',
            render: (created_at) => new moment(created_at).format('DD/MM/YY'),
        },
    ]

    return (
        <>
            <Space direction="vertical" size={32} style={{ width: '100%' }}>
                <SummaryRow items={[{ title: 'Total Guest Users', value: processedData?.paging.total_item_count }]} loading={loading} />
                <div>
                    <SearchInput loading={loading} onSearch={onSearch} />
                    <Spin spinning={loading}>
                        <Table columns={columns} dataSource={processedData?.items} rowKey={user => user.email} pagination={false} />
                    </Spin>
                </div>
            </Space>
            <DataPagination paging={processedData?.paging} itemType="guest users" onChange={onPageChange} />
        </>
    )
}

export default GuestUsersPanel

