import { Space, Spin, Table } from 'antd'
import TintAPI from '../../utils/TintAPI'
import { Column } from '@ant-design/charts'
import TooltipTitle from '../../components/TooltipTitle'
import AggregationUtils from '../../utils/AggregationUtils'
import useTintAPI from '../../hooks/useTintAPI'
import SummaryRow from '../../components/SummaryRow'

const ColoursFavoritesAnalyticsPanel = () => {
    const [data, loading] = useTintAPI(TintAPI.getFavoritesSummary, {}, [])

    let processedData = data
    const dataMap = {}

    if (data) {
        data.aggregation = AggregationUtils.addProportions(data.aggregation)
        data.aggregation = data.aggregation.sort((a, b) => b.count - a.count)

        for (const item of data.aggregation) {
            dataMap[item.key] = {
                name: item.meta.name,
                color: item.meta.color
            }
        }
    }

    const chartConfig = {
        data: data ? data.aggregation : [],
        xField: 'key',
        yField: 'count',
        meta: {
            key: {
                alias: 'Name',
                formatter: (key) => dataMap[key].name
            },
            count: { alias: 'Count' }
        },
        color: ({ key }) => dataMap[key].color
    }

    const columns = [
        {
            title: '',
            dataIndex: 'meta',
            key: 'color',
            width: '40px',
            render: meta => <div
                style={{ backgroundColor: meta.color, borderRadius: 4, width: '30px', height: '30px' }} />
        },
        {
            title: 'Name',
            dataIndex: 'meta',
            key: 'name',
            render: meta => meta.name
        },
        {
            title: 'Count',
            dataIndex: 'count',
            key: 'count'
        },
        {
            title: 'Proportion',
            dataIndex: 'proportion',
            key: 'proportion',
            render: proportion => `${(proportion * 100).toFixed(2)}%`
        }
    ]

    return (
        <Space direction="vertical" size={64} style={{ width: '100%' }}>
            <SummaryRow items={[{ title: 'Total Favourites', value: processedData?.count }]} loading={loading} />
            <TooltipTitle title="Favourites Distribution" tooltip="Only colour favourites are shown. Only favourites belonging to users are shown." />
            <Spin spinning={loading}>
                <Column {...chartConfig} />
            </Spin>
            <Spin spinning={loading}>
                <Table columns={columns} dataSource={processedData?.aggregation} pagination={false} />
            </Spin>
        </Space>
    )
}

export default ColoursFavoritesAnalyticsPanel
