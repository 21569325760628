import { Area } from '@ant-design/charts'
import { Radio } from 'antd'
import { useState } from 'react'
import TintAPI from '../../utils/TintAPI'
import DateRangePicker from '../DateRangePicker'
import IntervalSelector from './IntervalSelector'
import AggregationUtils from '../../utils/AggregationUtils'

const AreaChart = ({
                       stackedAggregation, isMoney,
                       defInterval, defDateRange,
                       intervalSelectorMode, showPercentageToggle, showDateRangePicker,
                       onIntervalChange, onDateRangeChange
                   }) => {
    const [interval, setInterval] = useState(defInterval || TintAPI.Analytics.Group.WEEK)
    const [dateRange, setDateRange] = useState(defDateRange || [null, null])
    const [displayType, setDisplayType] = useState('absolute')

    const config = {
        data: stackedAggregation ? stackedAggregation : [],
        xField: 'key',
        yField: 'count',
        seriesField: 'type',
        isPercent: displayType === 'percentage',
        meta: {
            key: {
                alias: 'Week',
                formatter: (key) => AggregationUtils.getIntervalString(key, interval)
            },
            count: {
                alias: 'Count',
                formatter: (count) => isMoney ? count.toMoneyString() : count.toLocaleString()
            }
        }
    }

    const onIntervalChangeInternal = (interval) => {
        setInterval(interval)
        onIntervalChange(interval)
    }
    const onDateRangeChangeInternal = range => {
        setDateRange(range)
        onDateRangeChange(range)
    }


    return (
        <>
            <Area {...config} style={{ height: 400 }} />
            <div style={{ marginTop: 24, display: 'flex' }}>
                <div style={{ flex: '1 1 0' }}>
                    {intervalSelectorMode && <IntervalSelector mode={intervalSelectorMode} defValue={interval} onChange={interval => onIntervalChangeInternal(interval)} />}
                </div>
                <div style={{ flex: '1 1 0' }}>
                    {showPercentageToggle &&
                        <Radio.Group defaultValue={displayType} style={{ textAlign: 'center', display: 'block' }} onChange={e => setDisplayType(e.target.value)}>
                            <Radio.Button value="absolute">Absolute</Radio.Button>
                            <Radio.Button value="percentage">Percentage</Radio.Button>
                        </Radio.Group>
                    }
                </div>
                <div style={{ textAlign: 'right', flex: '1 1 0' }}>
                    {showDateRangePicker &&
                        <DateRangePicker defValue={dateRange} onChange={onDateRangeChangeInternal} />
                    }
                </div>
            </div>
        </>
    )
}

export default AreaChart

