import { Space, Spin } from 'antd'
import TintAPI from '../../utils/TintAPI'
import useTintAPI from '../../hooks/useTintAPI'
import CohortTable from '../../components/analytics/CohortTable'
import { useState } from 'react'
import MultiCohortChart from '../../components/analytics/MultiCohortChart'
import AggregateTitle from '../../components/analytics/AggregateTitle'
import SampleConversionsAllTimeAnalyticsSection from './sections/SampleConversionsAllTimeAnalyticsSection'

const SampleConversionsCohortAnalyticsPanel = () => {
    const [selectedCohort, setSelectedCohort] = useState(null)
    const [selectedConversionDate, setSelectedConversionDate] = useState(null)
    const [aggregation, loading] = useTintAPI(TintAPI.getAnalyticsAggregation, {
        metrics: TintAPI.Analytics.Metrics.CUSTOMER_SAMPLE_JOURNEY_START_COHORT,
        group: TintAPI.Analytics.Group.TWO_MONTH
    }, [])

    const processedAggregation = aggregation?.aggregations[0].items.map(item => {
        return {
            converted: item.count - (item.convert_periods.hasOwnProperty(-1) ? parseInt(item.convert_periods[-1]) : 0),
            ...item
        }
    })


    return (
        <div>
            <Space direction="vertical" size={64} style={{ width: '100%' }}>
                <AggregateTitle title="Cohort Conversions" tooltip="Defined as the first paint purchase following a previous sample purchase."
                                suffix="conversions" aggregation={processedAggregation} />
                <Spin spinning={loading}>
                    <CohortTable data={processedAggregation} onCohortChange={setSelectedCohort} onConversionDateChange={setSelectedConversionDate} />
                </Spin>
                <Spin spinning={loading}>
                    <MultiCohortChart cohorts={processedAggregation} selectedCohortKey={selectedCohort?.key} />
                </Spin>
                <SampleConversionsAllTimeAnalyticsSection />
            </Space>
        </div>
    )
}

export default SampleConversionsCohortAnalyticsPanel

