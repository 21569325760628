import { Col, Row, Space, Spin, Statistic, Table, Tag } from 'antd';
import { useState } from "react";
import TintAPI from "../utils/TintAPI";
import DataPagination from "../components/DataPagination";
import moment from "moment";
import useTintAPI from "../hooks/useTintAPI";
import DateSearchInput from "../components/DateSearchInput";
import TooltipStatistic from "../components/TooltipStatistic";
import SummaryRow from '../components/SummaryRow'

const TintStylistCustomersPanel = () =>
{
    const [search, setSearch] = useState(null);
    const [dateRange, setDateRange] = useState([null, null]);
    const [page, setPage] = useState(1);
    const [data, loading] = useTintAPI(TintAPI.getTintStylistCustomers, {
        search: search,
        start: dateRange[0],
        end: dateRange[1],
        page: page
    }, [search, dateRange, page]);

    const onSearch = (search) =>
    {
        setPage(1);
        setSearch(search);
    };
    const onDateRangeChange = (range) =>
    {
        setPage(1);
        setDateRange(range);
    };
    const onPageChange = (page) =>
    {
        setPage(page);
    };


    const columns = [
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            render: (value, row) => <a href={`${TintAPI.SHOP_HOST}/admin/customers/${row.id}`} rel="noopener noreferrer" target="_blank">{value}</a>
        },
        {
            title: 'Total Order Value',
            dataIndex: 'total_order_value',
            key: 'total_order_value',
            render: (value) => <div style={{ color: value > 0 ? '#000' : '#888' }}>{value.toMoneyString()}</div>,
        },
        {
            title: 'Order Count',
            dataIndex: 'order_count',
            key: 'order_count',
            render: (value) => <div style={{ color: value > 0 ? '#000' : '#888' }}>{value}</div>,
        },
        {
            title: 'First Booked',
            dataIndex: 'first_booking_created_at',
            key: 'first_booking_created_at',
            render: (created_at) =>
            {
                const dt = new moment(created_at);
                return dt.isValid() ? dt.format('DD/MM/YY') : <Tag />
            },
        },
    ];


    return (
        <>
            <Space direction="vertical" size={48} style={{ width: '100%' }}>
                <SummaryRow items={[
                    { title: 'Total Tint Stylist Customers', value: data?.paging.total_item_count },
                    { title: 'Total Order Value', value: data?.summary.total_order_value?.toMoneyString(), tooltip: 'Order value is defined as the price of all the items in the order after both line-item and cart discounts have been applied. Excludes shipping costs and refunds.' }
                ]} loading={loading} />
                <div>
                    <DateSearchInput loading={loading} onSearch={onSearch} onDateRangeChange={onDateRangeChange} />
                    <Spin spinning={loading}>
                        <Table columns={columns} dataSource={data?.items} rowKey={item => item.email} pagination={false} />
                    </Spin>
                </div>
            </Space>
            <DataPagination paging={data?.paging} itemType="customers" onChange={onPageChange} />
        </>
    );
};

export default TintStylistCustomersPanel;

