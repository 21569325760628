const ColorUtils = {
    rgbToHex: function(rgb)
    {
        let hex = '#';

        hex += rgb.r.toString(16).padStart('0');
        hex += rgb.g.toString(16).padStart('0');
        hex += rgb.b.toString(16).padStart('0');

        return hex;
    },
    hexToRgb: function(hex)
    {
        if (hex.charAt(0) === '#')
            hex = hex.substr(1);

        var values = hex.split('');

        if (hex.length === 3)
        {
            const r = parseInt(values[0].toString() + values[0].toString(), 16);
            const g = parseInt(values[1].toString() + values[1].toString(), 16);
            const b = parseInt(values[2].toString() + values[2].toString(), 16);

            if (!isNaN(r) && !isNaN(g) && !isNaN(b))
                return [r, g, b];
        }
        else if (hex.length === 6)
        {
            const r = parseInt(values[0].toString() + values[1].toString(), 16);
            const g = parseInt(values[2].toString() + values[3].toString(), 16);
            const b = parseInt(values[4].toString() + values[5].toString(), 16);

            if (!isNaN(r) && !isNaN(g) && !isNaN(b))
                return [r, g, b];
        }

        return [255, 0, 255];
    },

    isDark: function(hex)
    {
        return ColorUtils.getLuminance(ColorUtils.hexToRgb(hex)) <= 210;
    },
    getLuminance: function(rgb)
    {
        return 0.2126 * rgb[0] + 0.7152 * rgb[1] + 0.0722 * rgb[2];
    },
};

export default ColorUtils;
