String.prototype.toTitleCase = function() {
    return this.replace(/\w\S*/g, (word) => {
        return word.charAt(0).toUpperCase() + word.substr(1).toLowerCase()
    })
}

Number.prototype.toMoneyString = function() {
    let money = (this / 100.0).toFixed(2)
    money = parseFloat(money).toLocaleString()

    const split = money.split('.')
    switch (split.length) {
        case 1:
            money += '.00'
            break
        case 2:
            money = money.padEnd(money.length + Math.max(2 - split[1].length, 0), '0')
            break
    }

    return `$${money}`
}

Array.prototype.sum = function() {
    return this.reduce((total, item) => total + item, 0)
}
Array.prototype.average = function() {
    if (this.length === 0) {
        return 0
    }

    return this.sum() / this.length;
};
