import {Space, Spin} from 'antd'
import TintAPI from '../../../utils/TintAPI'
import useTintAPI from '../../../hooks/useTintAPI'
import AggregationUtils from '../../../utils/AggregationUtils'
import AreaChart from '../../../components/analytics/AreaChart'
import {useMemo, useState} from 'react'
import AggregateTitle from '../../../components/analytics/AggregateTitle'
import DateUtils from '../../../utils/DateUtils'

const SampleConversionsAllTimeAnalyticsSection = () => {
    const [interval, setInterval] = useState(TintAPI.Analytics.Group.WEEK)
    const [dateRange, setDateRange] = useState(DateUtils.defEpochRange())
    const [aggregation, loading] = useTintAPI(TintAPI.getAnalyticsAggregation, {
        metrics: TintAPI.Analytics.Metrics.CUSTOMER_SAMPLE_JOURNEY,
        group: interval,
        start: dateRange[0],
        end: dateRange[1]
    }, [interval, dateRange])

    const processedAggregation = useMemo(() => {
        if (!aggregation)
            return null

        let processedAggregation = AggregationUtils.addMissingInterval(aggregation.aggregations[0].items, aggregation.aggregations[0].group)
        return AggregationUtils.addRunningTotals(processedAggregation)

    }, [aggregation])

    const stackedAggregation = useMemo(() => {
        if (!processedAggregation)
            return null

        return AggregationUtils.createStackedAggregation(processedAggregation, {
            sample_converted: 'Samples converted',
            sample_unconverted: 'Samples unconverted'
        }, aggregation.aggregations[0].group)

    }, [processedAggregation])


    return (
        <>
            <Space direction="vertical" size={64} style={{ width: '100%' }}>
                <AggregateTitle title="All Time Conversions" tooltip="Segmented by customer first sample order date."
                                suffix="sample customers" aggregation={processedAggregation} />
                <Spin spinning={loading}>
                    <AreaChart stackedAggregation={stackedAggregation}
                               defInterval={interval} defDateRange={dateRange}
                               intervalSelectorMode="extended" showPercentageToggle={true} showDateRangePicker={true}
                               onIntervalChange={interval => setInterval(interval)} onDateRangeChange={setDateRange} />
                </Spin>
            </Space>
        </>
    )
}

export default SampleConversionsAllTimeAnalyticsSection

