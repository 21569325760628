import Search from "antd/lib/input/Search";
import DateRangePicker from "./DateRangePicker";

const DateSearchInput = ({ loading, onSearch, onDateRangeChange }) =>
{
    const onInput = value =>
    {
        onSearch(value);
    };

    return (
        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 24 }}>
            <DateRangePicker onChange={onDateRangeChange} />
            <Search placeholder="Search" allowClear loading={loading} onSearch={onInput} style={{ width: 300 }} />
        </div>
    );
};

export default DateSearchInput;

