import { Button, Divider, Form, Input, List, Space } from 'antd';
import { getUser } from "../redux/selectors";
import { connect } from "react-redux";
import { useState } from "react";
import TintAPI from "../utils/TintAPI";
import { useHistory } from "react-router-dom";
import TooltipTitle from "../components/TooltipTitle";
import Text from "antd/lib/typography/Text";

const TintProUsersPanel = ({ user }) =>
{
    const history = useHistory();
    const [form] = Form.useForm();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);

    const validateMessages = {
        // eslint-disable-next-line
        required: '${name} is required',
        types: {
            email: 'Invalid email',
        },
    };

    const addCustomer = async (email, firstName, lastName) =>
    {
        setLoading(true);

        try
        {
            const resData = await TintAPI.addTintProUser(user.accessToken, email, firstName, lastName);
            setData([
                {
                    email: email,
                    error: false,
                    message: resData.message
                },
                ...data,
            ]);
        }
        catch (e)
        {
            if (e.code === TintAPI.ErrorCode.NOT_AUTHED)
                history.push('/logout');
            else
            {
                setData([
                    {
                        email: email,
                        error: true,
                        message: e.message ? e.message : e.code
                    },
                    ...data,
                ]);
            }
        }

        setLoading(false);
    };

    const onFinish = (values) =>
    {
        addCustomer(values.email, values.first_name, values.last_name);
    };


    const listItem = (item) => (
        <List.Item>
            <div>{item.email}</div>
            <Text type={item.error ? 'danger' : 'success'}>{item.message}</Text>
        </List.Item>
    );

    return (
        <>
            <Space direction="vertical" size={32} style={{ width: '100%' }}>
                <TooltipTitle title="Add Tint Pro User" tooltip="Adds a new Tint Pro user into the invite/welcome email flow." />
                <Form form={form} layout="inline" validateMessages={validateMessages} onFinish={onFinish}>
                    <Form.Item name="email" rules={[{ required: true, type: 'email' }]}>
                        <Input placeholder="Email*" />
                    </Form.Item>
                    <Form.Item name="first_name">
                        <Input placeholder="First name" />
                    </Form.Item>
                    <Form.Item name="last_name">
                        <Input placeholder="Last name" />
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit" loading={loading}>Add</Button>
                    </Form.Item>
                </Form>

                {data.length > 0 &&
                <div>
                    <Divider orientation="left" style={{ fontWeight: 600 }}>Add history</Divider>
                    <List itemLayout="horizontal" dataSource={data} renderItem={listItem} style={{ width: 600 }} />
                </div>
                }
            </Space>
        </>
    );
};

const mapStateToProps = state =>
{
    const user = getUser(state);
    return { user };
};

export default connect(mapStateToProps)(TintProUsersPanel);

