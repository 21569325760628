import { Button, message, notification } from "antd";
import { store } from "../redux/store";
import { addColorPin, removeColorPin } from "../redux/actions";

const ViewUtils = {
    copyCodeToClipboard: (code) =>
    {
        navigator.clipboard.writeText(code);
        message.success(<><strong>{code}</strong> copied to clipboard</>);
    },

    showColorPin: function(color)
    {
        store.dispatch(addColorPin(color));

        const args = {
            key: color.handle,
            description:
                <div style={{ display: 'flex' }}>
                    <div style={{ backgroundColor: color.hex, borderRadius: '50%', width: 64, height: 64 }} />
                    <div style={{ marginLeft: 16 }}>
                        <div style={{ fontSize: 12, color: '#888' }}>{color.brand}</div>
                        <div style={{ lineHeight: 1 }}>{color.name}</div>
                        <Button size="small" style={{ marginTop: 8 }} onClick={() => ViewUtils.copyCodeToClipboard(color.code)}>{color.code}</Button>
                    </div>
                </div>,
            duration: 0,
            top: 84,
            className: 'color-pin',
            onClose: () =>
            {
                store.dispatch(removeColorPin(color));
            }
        };
        notification.open(args);
    },
};

export default ViewUtils;
