import { Space } from 'antd'
import TintAPI from '../../utils/TintAPI'
import StackedAggregateChart from '../../components/analytics/StackedAggregateChart'
import AggregationUtils from '../../utils/AggregationUtils'
import AggregateChart from '../../components/analytics/AggregateChart'
import useTintAPI from '../../hooks/useTintAPI'
import { useMemo, useState } from 'react'
import DateUtils from '../../utils/DateUtils'
import AggregateTitle from '../../components/analytics/AggregateTitle'

const OrdersRefundsPanel = () => {
    const [refundsInterval, setRefundsInterval] = useState(TintAPI.Analytics.Group.WEEK)
    const [refundsDateRange, setRefundsDateRange] = useState(DateUtils.defEpochRange())
    const [refundsAggregation, refundsLoading] = useTintAPI(TintAPI.getAnalyticsAggregation, {
        metrics: TintAPI.Analytics.Metrics.ORDER_REFUNDS,
        group: refundsInterval,
        start: refundsDateRange[0],
        end: refundsDateRange[1]
    }, [refundsInterval, refundsDateRange])

    const processedAggregation = useMemo(() => {
        if (!refundsAggregation) {
            return null
        }

        let processedAggregation = AggregationUtils.addMissingInterval(refundsAggregation.aggregations[0].items, refundsAggregation.aggregations[0].group)
        processedAggregation = AggregationUtils.addRollingPercent(processedAggregation, 'refund_count', 'order_count', 'rolling_percent')

        for (let i = 0; i < processedAggregation.length; i++) {
            processedAggregation[i].refund_percent = processedAggregation[i].order_count > 0 ? processedAggregation[i].refund_count / processedAggregation[i].order_count * 100 : 0
        }

        return AggregationUtils.addRunningTotals(processedAggregation)
    }, [refundsAggregation])

    const refundsTypeAggregation = useMemo(() => {
        if (!processedAggregation) {
            return null
        }

        return AggregationUtils.createStackedAggregation(processedAggregation, {
            reason_unknown_count: 'Unknown',
            reason_c_sat_count: 'c_sat',
            reason_forgot_code_count: 'Forgot code',
        }, refundsAggregation.aggregations[0].group)
    }, [processedAggregation])


    return (
        <>
            <Space direction="vertical" size={64} style={{ width: '100%' }}>
                <AggregateTitle title="Refund by Reason" tooltip="Date represents the order date, not the refund date."
                                suffix="refunds" aggregation={processedAggregation} aggregationKey="refund_count" />
                <StackedAggregateChart stackedAggregation={refundsTypeAggregation} isLoading={refundsLoading}
                                       lineId="rolling_count" lineTitle="4-pt rolling avg"
                                       defInterval={refundsInterval} defDateRange={refundsDateRange}
                                       intervalSelectorMode="extended" showPercentageToggle={true} showDateRangePicker={true}
                                       onIntervalChange={setRefundsInterval} onDateRangeChange={setRefundsDateRange} />

                <AggregateTitle title="Refund by Value" tooltip="Date represents the order date, not the refund date."
                                suffix="value" aggregation={processedAggregation} aggregationKey="refund_amount" isMoney={true} />
                <AggregateChart aggregation={processedAggregation} isLoading={refundsLoading}
                                yField="refund_amount" yType="money" lineId="rolling_amount" lineType="money" lineTitle="4-pt rolling avg"
                                defInterval={refundsInterval} defDateRange={refundsDateRange}
                                intervalSelectorMode="extended" showDateRangePicker={true}
                                onIntervalChange={setRefundsInterval} onDateRangeChange={setRefundsDateRange} />

                <AggregateTitle title="Refund Proportion" tooltip="Percentage of all orders which were refunded."
                                suffix="refunds" aggregation={processedAggregation} aggregationKey="refund_count" />
                <AggregateChart aggregation={processedAggregation} lineAggregation={processedAggregation} isLoading={refundsLoading}
                                yField="refund_percent" yType="percent" lineId="rolling_percent" lineType="percent" lineMax={10} lineTitle="4-pt rolling avg"
                                defInterval={refundsInterval} defDateRange={refundsDateRange}
                                intervalSelectorMode="extended" showDateRangePicker={true}
                                onIntervalChange={setRefundsInterval} onDateRangeChange={setRefundsDateRange} />
            </Space>
        </>
    )
}

export default OrdersRefundsPanel

