import moment from "moment";

const DateUtils = {
    defRange: function()
    {
        return [
            new moment().subtract(3, 'months').startOf('day'),
            new moment().endOf('day')
        ];
    },
    defEpochRange: function()
    {
        const defRange = DateUtils.defRange();

        return [
            defRange[0].valueOf() / 1000,
            defRange[1].valueOf() / 1000,
        ];
    },

    toDateEpoch: function(dt, endOfDay)
    {
        if (!dt)
            return null;

        if (endOfDay)
            dt = dt.endOf('day');
        else
            dt = dt.startOf('day');

        return dt.valueOf() / 1000;
    }
};

export default DateUtils;
