import { Line } from '@ant-design/charts'
import { useMemo } from 'react'
import moment from 'moment'
import { Spin } from 'antd'

const UnfulfilledChart = ({ aggregation, isLoading }) => {
    const processedAggregation = useMemo(() => {
        if (!aggregation) {
            return []
        }

        const items = aggregation.aggregations[0].items

        if (items.length === 0) {
            return []
        }

        const firstDt = moment(items[0].created_at, 'YYYY-MM-DD-H')
        const dt = moment(items[0].created_at, 'YYYY-MM-DD-H')
        const now = moment()
        const days = now.diff(firstDt, 'hours')
        const line = []

        for (let i = 0; i <= days; i++) {
            line.push({
                key: dt.format('YYYY-MM-DD-H'),
                total: 0
            })
            dt.add(1, 'hour')
        }

        for (const item of items) {
            const startDt = moment(item.created_at, 'YYYY-MM-DD-H')
            const endDt = item.closed_at ? moment(item.closed_at, 'YYYY-MM-DD-H') : null
            const start = startDt.diff(firstDt, 'hours')
            const end = endDt ? endDt.diff(firstDt, 'hours') : line.length
            for (let i = start; i < end; i++) {
                line[i].total++
            }
        }

        return line
    }, [aggregation])

    const config = useMemo(() => {
        const config = {
            data: processedAggregation,
            xField: 'key',
            yField: 'total',
            slider: {
                start: 0,
                end: processedAggregation.length,
                height: 50,
                formatter: value => new moment(value, 'YYYY-MM-DD-H').format('ddd ha DD/MM')
            },
            meta: {
                key: {
                    alias: 'Hour',
                    formatter: key => new moment(key, 'YYYY-MM-DD-H').format('ddd ha DD/MM')
                },
                total: {
                    alias: 'Total',
                    formatter: value => value.toLocaleString()
                }
            }
        }

        return config
    }, [aggregation])


    return (
        <Spin spinning={isLoading}>
            <Line {...config} style={{ height: 600 }} />
        </Spin>
    )
}

export default UnfulfilledChart

