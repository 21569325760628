import Search from "antd/lib/input/Search";

const SearchInput = ({ loading, onSearch }) =>
{
    const onInput = value =>
    {
        onSearch(value);
    };

    return (
        <div style={{ textAlign: 'right', marginBottom: 24 }}>
            <Search placeholder="Search" allowClear loading={loading} onSearch={onInput} style={{ width: 300 }} />
        </div>
    );
};

export default SearchInput;

