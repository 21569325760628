import { Row, Col } from 'antd';
import LoginForm from "../components/LoginForm";

const LoginPage = () =>
{
    document.title = 'Login';

    return (
        <>
            <Row type="flex" justify="center" align="middle" style={{ minHeight: '100vh' }}>
                <Col flex="400px">
                    <img className="logo" src="/logo192.png" style={{ borderRadius: 4, height: 64, marginBottom: 48 }} />
                    <LoginForm />
                </Col>
            </Row>
        </>
    );
};

export default LoginPage;
