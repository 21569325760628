import { Space, Spin } from 'antd'
import TintAPI from '../../utils/TintAPI'
import TooltipTitle from '../../components/TooltipTitle'
import Map from '../../components/analytics/Map'
import useTintAPI from '../../hooks/useTintAPI'
import SummaryRow from '../../components/SummaryRow'

const ColoursMapAnalyticsPanel = () => {
    const metaData = [
        { id: 'sample', colors: ['#0088ff', '#016ecd'], title: 'Sample - Tint' },
        { id: 'predefined_paint', colors: ['#62d451', '#54b346'], title: 'Paint - Tint' },
        { id: 'custom_paint', colors: ['#565656', '#3e3e3e'], title: 'Paint - Custom' }
    ]

    const [data, loading] = useTintAPI(TintAPI.getAnalyticsMap, {
        metric: TintAPI.Analytics.Metrics.ORDER_COLOR
    }, [])

    const getClassification = feature => feature.classification


    return (
        <Space direction="vertical" size={64} style={{ width: '100%' }}>
            <SummaryRow items={[{ title: 'Total Colours', value: data?.length }]} loading={loading} />
            <div>
                <TooltipTitle title="Colour Map" tooltip="Colour location based on shipping address. Colours without a valid shipping address are ignored." style={{ marginBottom: 32 }} />
                <Spin spinning={loading}>
                    <Map data={data} metaData={metaData} getClassification={getClassification} markerDisplayMode="color" />
                </Spin>
            </div>
        </Space>
    );
};

export default ColoursMapAnalyticsPanel;

