import '../DataPagination.scss'
import { DualAxes } from '@ant-design/charts'
import { useState } from 'react'
import TintAPI from '../../utils/TintAPI'
import { Spin } from 'antd'
import DateRangePicker from '../DateRangePicker'
import IntervalSelector from './IntervalSelector'
import AggregationUtils from '../../utils/AggregationUtils'

const AggregateChart = ({
                            aggregation, lineAggregation, isLoading,
                            yField, yType, lineId, lineType, lineMax, lineTitle,
                            defInterval, defDateRange,
                            intervalSelectorMode, showDateRangePicker,
                            onIntervalChange, onDateRangeChange
                        }) => {
    yField = yField || 'count'
    lineId = lineId || 'total'
    const [interval, setInterval] = useState(defInterval || TintAPI.Analytics.Group.WEEK)
    const [dateRange, setDateRange] = useState(defDateRange || [null, null])

    const meta = {
        key: {
            alias: 'Week',
            formatter: key => AggregationUtils.getIntervalString(key, interval)
        }
    }

    meta[yField] = {
        alias: (() => {
            switch (yType) {
                case 'percent':
                    return 'Percentage'
                case 'money':
                    return 'Value'
                default:
                    return 'Count'
            }
        })(),
        formatter: value => {
            if (!value) {
                return null
            }
            switch (yType) {
                case 'percent':
                    return `${value.toFixed(2)}%`
                case 'money':
                    return value.toMoneyString()
                default:
                    return value.toLocaleString()
            }
        }
    }
    meta[lineId] = {
        alias: lineTitle ? lineTitle : 'Total',
        formatter: value => {
            if (!value) {
                return null
            }
            switch (lineType) {
                case 'percent':
                    return `${value.toFixed(2)}%`
                case 'money':
                    return value.toMoneyString()
                default:
                    return value.toLocaleString()
            }
        }
    }

    if (lineType === 'percent') {
        meta[lineId].min = 0
        meta[lineId].max = lineMax || 100
    }

    const config = {
        data: [aggregation || [], lineAggregation || []],
        xField: 'key',
        yField: [yField, lineId],
        geometryOptions: [
            { geometry: 'column' },
            {
                geometry: 'line',
                lineStyle: { lineWidth: 2 }
            }
        ],
        meta
    }

    const onIntervalChangeInternal = interval => {
        setInterval(interval)
        onIntervalChange(interval)
    }
    const onDateRangeChangeInternal = range => {
        setDateRange(range)
        onDateRangeChange(range)
    }


    return (
        <Spin spinning={isLoading}>
            <DualAxes {...config} style={{ height: 400 }} />
            <div style={{ marginTop: 24, display: 'flex' }}>
                <div style={{ flex: '1 1 0' }}>
                    {intervalSelectorMode && <IntervalSelector mode={intervalSelectorMode} defValue={interval} onChange={interval => onIntervalChangeInternal(interval)} />}
                </div>
                <div style={{ flex: '1 1 0' }} />
                <div style={{ textAlign: 'right', flex: '1 1 0' }}>
                    {showDateRangePicker &&
                        <DateRangePicker defValue={dateRange} onChange={onDateRangeChangeInternal} />
                    }
                </div>
            </div>
        </Spin>
    );
};

export default AggregateChart;

