import { Space } from 'antd'
import TintAPI from '../../utils/TintAPI'
import useTintAPI from '../../hooks/useTintAPI'
import StackedAggregateChart from '../../components/analytics/StackedAggregateChart'
import AggregationUtils from '../../utils/AggregationUtils'
import { useMemo, useState } from 'react'
import DateUtils from '../../utils/DateUtils'
import AggregateTitle from '../../components/analytics/AggregateTitle'

const OrdersPaintLitresAnalyticsPanel = () => {
    const [interval, setInterval] = useState(TintAPI.Analytics.Group.WEEK)
    const [dateRange, setDateRange] = useState(DateUtils.defEpochRange())
    const [aggregation, loading] = useTintAPI(TintAPI.getAnalyticsAggregation, {
        metrics: TintAPI.Analytics.Metrics.ORDER_ITEM_PAINT_LITRES,
        group: interval,
        start: dateRange[0],
        end: dateRange[1]
    }, [interval, dateRange])

    const processedAggregation = useMemo(() => {
        if (!aggregation)
            return null

        let processedAggregation = AggregationUtils.addMissingInterval(aggregation.aggregations[0].items, aggregation.aggregations[0].group)
        return AggregationUtils.addRunningTotals(processedAggregation)

    }, [aggregation])

    const stackedAggregation = useMemo(() => {
        if (!processedAggregation)
            return null

        return AggregationUtils.createStackedAggregation(processedAggregation, {
            predefined_paint_litres: 'Tint colour',
            custom_paint_litres: 'Custom colour',
            prep_ceiling_paint_litres: 'Prep/ceiling base'
        }, aggregation.aggregations[0].group)

    }, [processedAggregation])


    return (
        <>
            <Space direction="vertical" size={64} style={{ width: '100%' }}>
                <AggregateTitle title="Total Paint Litres" suffix="litres" aggregation={processedAggregation} />
                <StackedAggregateChart stackedAggregation={stackedAggregation} isLoading={loading}
                                       defInterval={interval} defDateRange={dateRange}
                                       intervalSelectorMode="extended" showPercentageToggle={true} showDateRangePicker={true}
                                       onIntervalChange={setInterval} onDateRangeChange={setDateRange} />
            </Space>
        </>
    )
};

export default OrdersPaintLitresAnalyticsPanel

