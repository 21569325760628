import { Space } from 'antd'
import TintAPI from '../../utils/TintAPI'
import AggregateChart from '../../components/analytics/AggregateChart'
import useTintAPI from '../../hooks/useTintAPI'
import AggregationUtils from '../../utils/AggregationUtils'
import DateUtils from '../../utils/DateUtils'
import { useMemo, useState } from 'react'
import AggregateTitle from '../../components/analytics/AggregateTitle'
import AccountsNewsletterAnalyticsSection from './sections/AccountsNewsletterAnalyticsSection'
import AccountsTintProAnalyticsSection from './sections/AccountsTintProAnalyticsSection'

const AccountsPanel = () => {
    const [interval, setInterval] = useState(TintAPI.Analytics.Group.WEEK)
    const [dateRange, setDateRange] = useState(DateUtils.defEpochRange())
    const [aggregation, loading] = useTintAPI(TintAPI.getAnalyticsAggregation, {
        metrics: TintAPI.Analytics.Metrics.USER_COUNT,
        group: interval,
        start: dateRange[0],
        end: dateRange[1]
    }, [interval, dateRange])

    const processedAggregation = useMemo(() => {
        if (!aggregation) {
            return null
        }

        let result = AggregationUtils.addMissingInterval(aggregation.aggregations[0].items, aggregation.aggregations[0].group)
        result = AggregationUtils.addRunningTotals(result)
        return result

    }, [aggregation])


    return (
        <>
            <Space direction="vertical" size={64} style={{ width: '100%' }}>
                <AggregateTitle title="Account Signups" tooltip="Represents Tint website accounts which have been active on the website at least once."
                                suffix="signup" aggregation={processedAggregation} />
                <AggregateChart aggregation={processedAggregation} isLoading={loading}
                                defInterval={interval} defDateRange={dateRange}
                                intervalSelectorMode="basic" showDateRangePicker={true}
                                onIntervalChange={setInterval} onDateRangeChange={setDateRange} />
                <AccountsNewsletterAnalyticsSection />
                <AccountsTintProAnalyticsSection />
            </Space>
        </>
    )
}

export default AccountsPanel

