import { Space, Spin, Table, Tag } from 'antd'
import { useState } from 'react'
import TintAPI from '../utils/TintAPI'
import DataPagination from '../components/DataPagination'
import moment from 'moment'
import useTintAPI from '../hooks/useTintAPI'
import SummaryRow from '../components/SummaryRow'

const UsersPanel = () => {
    const [page, setPage] = useState(1)
    const [sort, setSort] = useState(null)
    const [data, loading] = useTintAPI(TintAPI.getUsers, {
        page: page,
        sort: sort
    }, [page, sort])

    const processedData = data?.users


    const onTableChange = (pagination, filter, sorter) => {
        setSort({
            field: sorter.field,
            dir: { ascend: 'asc', descend: 'desc' }[sorter.order]
        })
    }
    const onPageChange = (page) => {
        setPage(page)
    }


    const columns = [
        {
            title: 'Shopify ID',
            dataIndex: 'shopify_id',
            key: 'shopify_id',
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            sorter: (a, b) => 0,
            sortDirections: ['descend', 'ascend'],
            render: email => email ? email : <Tag />,
        },
        {
            title: 'Acquired',
            dataIndex: 'created_at',
            key: 'created_at',
            render: (created_at) => new moment(created_at).format('DD/MM/YY'),
        },
    ]

    return (
        <>
            <Space direction="vertical" size={64} style={{ width: '100%' }}>
                <SummaryRow items={[{ title: 'Total Users', value: processedData?.paging.total_item_count }]} loading={loading} />
                <Spin spinning={loading}>
                    <Table columns={columns} dataSource={processedData?.items} rowKey={user => user.shopify_id} pagination={false} onChange={onTableChange} />
                </Spin>
            </Space>
            <DataPagination paging={processedData?.paging} itemType="users" onChange={onPageChange} />
        </>
    )
}

export default UsersPanel

