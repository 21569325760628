import { Space, Spin, Table, Tag } from 'antd'
import { useState } from 'react'
import TintAPI from '../utils/TintAPI'
import DataPagination from '../components/DataPagination'
import moment from 'moment'
import SearchInput from '../components/SearchInput'
import useTintAPI from '../hooks/useTintAPI'
import SummaryRow from '../components/SummaryRow'

const TintProApplicationsPanel = () => {
    const [search, setSearch] = useState(null)
    const [page, setPage] = useState(1)
    const [data, loading] = useTintAPI(TintAPI.getTintProApplications, {
        search: search,
        page: page
    }, [search, page])

    const processedData = data?.tint_pro_applications

    const onSearch = (search) => {
        setPage(1)
        setSearch(search)
    }
    const onPageChange = (page) => {
        setPage(page)
    }


    const columns = [
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email'
        },
        {
            title: 'First Name',
            dataIndex: 'first_name',
            key: 'first_name'
        },
        {
            title: 'Last Name',
            dataIndex: 'last_name',
            key: 'last_name'
        },
        {
            title: 'Phone',
            dataIndex: 'phone',
            key: 'phone'
        },
        {
            title: 'ABN',
            dataIndex: 'abn',
            key: 'abn'
        },
        {
            title: 'Type',
            dataIndex: 'trade_specifier',
            key: 'trade_specifier',
            render: (tag) => {
                let color = null
                switch (tag) {
                    case 'Trade':
                        color = 'blue'
                        break
                    case 'Specifier':
                        color = 'purple'
                        break
                    default:
                        break
                }

                return (
                    <Tag color={color}>{tag}</Tag>
                )
            }
        },
        {
            title: 'Industry',
            dataIndex: 'industry_type',
            key: 'industry_type'
        },
        {
            title: 'Directory',
            dataIndex: 'in_tint_pro_directory',
            key: 'in_tint_pro_directory',
            render: (flag) => (
                <Tag color={flag ? 'green' : null}>{flag && 'YES'}</Tag>
            )
        },
        {
            title: 'Discovery',
            dataIndex: 'discovery_method',
            key: 'discovery_method'
        },
        {
            title: 'Acquired',
            dataIndex: 'created_at',
            key: 'created_at',
            render: (created_at) => new moment(created_at).format('DD/MM/YY')
        }
    ]

    return (
        <>
            <Space direction="vertical" size={32} style={{ width: '100%' }}>
                <SummaryRow items={[{ title: 'Total Tint Pro Applications', value: processedData?.paging.total_item_count }]} loading={loading} />
                <div>
                    <SearchInput loading={loading} onSearch={onSearch} />
                    <Spin spinning={loading}>
                        <Table columns={columns} dataSource={processedData?.items} rowKey={app => app.email + app.created_at} pagination={false} />
                    </Spin>
                </div>
            </Space>
            <DataPagination paging={processedData?.paging} itemType="applications" onChange={onPageChange} />
        </>
    )
}

export default TintProApplicationsPanel

