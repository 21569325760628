import { Layout, Menu, Tabs } from 'antd'
import { UserOutlined, AuditOutlined, SkinOutlined, IdcardOutlined, FireOutlined, NodeIndexOutlined, LineChartOutlined, ScissorOutlined } from '@ant-design/icons'
import './DashboardPage.scss'
import DashboardHeader from '../components/DashboardHeader'
import ProductsPanel from '../panels/ProductsPanel'
import GuestUsersPanel from '../panels/GuestUsersPanel'
import UsersPanel from '../panels/UsersPanel'
import ColoursFavoritesAnalyticsPanel from '../panels/analytics/ColoursFavoritesAnalyticsPanel'
import NewYearNewHomeEntriesPanel from '../panels/NewYearNewHomeEntriesPanel'
import ColoursTintMatchBrandsAnalyticsPanel from '../panels/analytics/ColoursTintMatchBrandsAnalyticsPanel'
import TintProUsersPanel from '../panels/TintProUsersPanel'
import DraftOrdersPanel from '../panels/DraftOrdersPanel'
import { Link, useLocation, useHistory } from 'react-router-dom'
import TintMatchColoursPanel from '../panels/TintMatchColoursPanel'
import TintMatchSearchPanel from '../panels/TintMatchSearchPanel'
import { getColorPins, getUISettings } from '../redux/selectors'
import { connect } from 'react-redux'
import { useEffect, Fragment, useCallback } from 'react'
import ViewUtils from '../utils/ViewUtils'
import TintProApplicationsPanel from '../panels/TintProApplicationsPanel'
import OrdersAnalyticsPanel from '../panels/analytics/OrdersAnalyticsPanel'
import { applyUISettings } from '../redux/actions'
import AccountsPanel from '../panels/analytics/AccountsPanel'
import AccountsNewsletterAnalyticsSection from '../panels/analytics/sections/AccountsNewsletterAnalyticsSection'
import AccountsTintProAnalyticsSection from '../panels/analytics/sections/AccountsTintProAnalyticsSection'
import OrdersContentMapAnalyticsPanel from '../panels/analytics/OrdersContentMapAnalyticsPanel'
import SampleConversionsMapAnalyticsPanel from '../panels/analytics/SampleConversionsMapAnalyticsPanel'
import OrdersPaintLitresAnalyticsPanel from '../panels/analytics/OrdersPaintLitresAnalyticsPanel'
import SampleConversionsCohortAnalyticsPanel from '../panels/analytics/SampleConversionsCohortAnalyticsPanel'
import LogtisticsDeliveryAnalyticsPanel from '../panels/analytics/LogtisticsDeliveryAnalyticsPanel'
import TintStylistCustomersPanel from '../panels/TintStylistCustomersPanel'
import TintStylistOrdersPanel from '../panels/TintStylistOrdersPanel'
import SampleConversionsAllTimeAnalyticsSection from '../panels/analytics/sections/SampleConversionsAllTimeAnalyticsSection'
import LogisticsDispatchAnalyticsPanel from '../panels/analytics/LogisticsDispatchAnalyticsPanel'
import OrdersRefundsPanel from '../panels/analytics/OrdersRefundsPanel'
import ColoursMapAnalyticsPanel from '../panels/analytics/ColoursMapAnalyticsPanel'
import LocalSettingsPanel from '../panels/other/LocalSettingsPanel'

const { TabPane } = Tabs
const { Sider, Content } = Layout
const { SubMenu } = Menu

const DashboardPage = ({ colorPins, uiSettings, applyUISettings }) => {
    const location = useLocation()
    const history = useHistory()

    useEffect(() => {
        for (const pin of colorPins) {
            ViewUtils.showColorPin(pin)
        }

        // eslint-disable-next-line
    }, [])

    const Panel = useCallback(({ component }) => {
        return <div className="content-panel">{component}</div>
    }, [])

    const menu = [
        {
            key: 'users',
            title: 'Users',
            icon: <UserOutlined />,
            items: [
                { key: '', title: 'Users', component: <UsersPanel /> },
                { key: 'guest-users', title: 'Guest Users', component: <GuestUsersPanel /> }
            ]
        },
        { key: 'products', title: 'Products', icon: <SkinOutlined />, component: <ProductsPanel /> },
        {
            key: 'orders',
            title: 'Orders',
            icon: <AuditOutlined />,
            items: [
                { key: 'draft-orders', title: 'Draft Orders', component: <DraftOrdersPanel /> }
            ]
        },
        {
            key: 'tint-match',
            title: 'Tint Match',
            icon: <NodeIndexOutlined />,
            items: [
                { key: '', title: 'Colours', component: <TintMatchColoursPanel /> },
                { key: 'search', title: 'Search', component: <TintMatchSearchPanel /> }
            ]
        },
        {
            key: 'tint-stylist',
            title: 'Tint Stylist',
            icon: <ScissorOutlined />,
            items: [
                { key: 'customers', title: 'Customers', component: <TintStylistCustomersPanel /> },
                { key: 'orders', title: 'Orders', component: <TintStylistOrdersPanel /> }
            ]
        },
        {
            key: 'tint-pro',
            title: 'Tint Pro',
            icon: <IdcardOutlined />,
            items: [
                { key: 'users', title: 'Users', component: <TintProUsersPanel /> },
                { key: 'applications', title: 'Applications', component: <TintProApplicationsPanel /> }
            ]
        },
        {
            key: 'events',
            title: 'Events',
            icon: <FireOutlined />,
            items: [
                { key: 'new-year-new-home', title: 'New Year New Home', component: <NewYearNewHomeEntriesPanel /> }
            ]
        },
        {
            key: 'analytics',
            title: 'Analytics',
            icon: <LineChartOutlined />,
            items: [
                { key: 'accounts-newsletter', title: 'Accounts & Newsletter', component: <AccountsPanel /> },
                {
                    key: 'sample-conversions', title: 'Sample Conversions', tabs: [
                        { key: 'cohort', title: 'Sample Cohorts', component: <SampleConversionsCohortAnalyticsPanel /> },
                        { key: 'map', title: 'Sample Conversions Map', component: <SampleConversionsMapAnalyticsPanel /> }
                    ]
                },
                {
                    key: 'colours', title: 'Colours', tabs: [
                        { key: 'favourites', title: 'Favourites', component: <ColoursFavoritesAnalyticsPanel /> },
                        { key: 'tint-match', title: 'Tint Match', component: <ColoursTintMatchBrandsAnalyticsPanel /> },
                        { key: 'color-map', title: 'Colour Map', component: <ColoursMapAnalyticsPanel /> }
                    ]
                },
                {
                    key: 'orders', title: 'Orders', tabs: [
                        { key: 'orders', title: 'Orders', component: <OrdersAnalyticsPanel /> },
                        { key: 'refunds', title: 'Refunds', component: <OrdersRefundsPanel /> },
                        { key: 'paint-litres', title: 'Paint Litres', component: <OrdersPaintLitresAnalyticsPanel /> },
                        { key: 'content-map', title: 'Content Map', component: <OrdersContentMapAnalyticsPanel /> }
                    ]
                },
                {
                    key: 'logistics', title: 'Logistics', tabs: [
                        { key: 'delivery', title: 'Delivery', component: <LogtisticsDeliveryAnalyticsPanel /> },
                        { key: 'dispatch', title: 'Dispatch', component: <LogisticsDispatchAnalyticsPanel /> },
                    ]
                }
            ]
        }
    ]

    const otherPanels = [
        { key: 'local-settings', component: <LocalSettingsPanel /> }
    ]

    const path = location.pathname
    const fullPath = `${path}${location.search}`
    const parentKey = '/' + path.split('/')[1]

    document.title = (() => {
        for (const submenu of menu) {
            if (path.substr(0, path.length - (path.endsWith('/') ? 1 : 0)) === `/${submenu.key}`) {
                return submenu.title
            }

            if (!submenu.items) {
                continue
            }

            for (const item of submenu.items) {
                if (path === `/${submenu.key}/${item.key}`) {
                    return `${item.title} - ${submenu.title}`;
                }
            }
        }

        return 'Tint Mix';
    })()

    return (
        <Layout className="dashboard-page">
            <DashboardHeader />
            <Layout>
                <Sider collapsible collapsed={uiSettings.sidebarCollapsed} onCollapse={collapsed => applyUISettings({ sidebarCollapsed: collapsed })}>
                    <Menu mode="inline" defaultOpenKeys={[parentKey]} selectedKeys={[path]}>
                        {menu.map(submenu =>
                            <Fragment key={submenu.key}>
                                {submenu.component &&
                                    <Menu.Item key={`/${submenu.key}`} icon={submenu.icon}><Link to={`/${submenu.key}`}>{submenu.title}</Link></Menu.Item>
                                }
                                {submenu.items &&
                                    <SubMenu key={`/${submenu.key}`} icon={submenu.icon} title={submenu.title}>
                                        {submenu.items.map(item =>
                                            <Menu.Item key={`/${submenu.key}/${item.key}`}><Link to={`/${submenu.key}/${item.key}`}>{item.title}</Link></Menu.Item>
                                        )}
                                    </SubMenu>
                                }
                            </Fragment>
                        )}
                    </Menu>
                </Sider>
                <Content style={{ marginLeft: uiSettings.sidebarCollapsed ? 104 : 224 }}>
                    {menu.map(submenu =>
                        <Fragment key={submenu.key}>
                            {submenu.component && path === `/${submenu.key}` && <Panel component={submenu.component} />}
                            {submenu.items && submenu.items.map(item =>
                                <Fragment key={item.key}>
                                    {path === `/${submenu.key}/${item.key}` &&
                                        <>
                                            {!item.tabs && <Panel component={item.component} />}
                                            {item.tabs &&
                                                <Tabs className="content-panel-container" defaultActiveKey={fullPath} type="card" onTabClick={key => history.push(key)}>
                                                    {item.tabs.map(tab =>
                                                        <TabPane key={`/${submenu.key}/${item.key}?tab=${tab.key}`} tab={tab.title}>
                                                            <Panel component={tab.component} />
                                                        </TabPane>
                                                    )}
                                                </Tabs>
                                            }
                                        </>
                                    }
                                </Fragment>
                            )}
                        </Fragment>
                    )}
                    {otherPanels.map(panel =>
                        <Fragment key={panel.key}>
                            {panel.component && path === `/${panel.key}` && <Panel component={panel.component} />}
                        </Fragment>
                    )}
                </Content>
            </Layout>
        </Layout>
    )
}

const mapStateToProps = state => {
    const colorPins = getColorPins(state)
    const uiSettings = getUISettings(state)
    return { colorPins, uiSettings }
}

export default connect(mapStateToProps, { applyUISettings })(DashboardPage)
