import { Checkbox, Space } from 'antd'
import Title from 'antd/es/typography/Title'
import { getLocalSettings } from '../../redux/selectors'
import { connect } from 'react-redux'
import { applyLocalSettings } from '../../redux/actions'

const LocalSettingsPanel = ({ localSettings, applyLocalSettings }) => {
    const onChange = (e) => {
        applyLocalSettings({ showSummaryCounts: e.target.checked })
    }

    return (
        <>
            <Space direction="vertical" size={64} style={{ width: '100%' }}>
                <Space direction="vertical" style={{ width: '100%' }}>
                    <Title>Local Settings</Title>
                    <div className="subtitle" style={{ fontSize: 14 }}>These settings local to the browser and will be reset whenever cookies are cleared.</div>
                </Space>

                <Space direction="vertical" style={{ width: '100%' }}>
                    <Title level={3}>General</Title>
                    <Checkbox defaultChecked={localSettings.showSummaryCounts} onChange={onChange}>Show summary counts</Checkbox>
                </Space>
            </Space>
        </>
    )
}

const mapStateToProps = state => {
    const localSettings = getLocalSettings(state)
    return { localSettings }
}

export default connect(mapStateToProps, { applyLocalSettings })(LocalSettingsPanel)
