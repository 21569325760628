import { Space, Button, Spin, Col, Row } from 'antd'
import { getUser } from '../redux/selectors'
import { connect } from 'react-redux'
import { useEffect, useState } from 'react'
import TintAPI from '../utils/TintAPI'
import { useHistory } from 'react-router-dom'
import TooltipTitle from '../components/TooltipTitle'
import TintMatchOrderLineItem from '../components/TintMatchOrderLineItem'
import Title from 'antd/lib/typography/Title'
import Text from 'antd/lib/typography/Text'

const DraftOrdersPanel = ({ user }) => {
    const history = useHistory()
    const [lineItems, setLineItems] = useState([])
    const [products, setProducts] = useState(null)
    const [createResult, setCreateResult] = useState(null)
    const [productLoading, setProductLoading] = useState(false)
    const [submitLoading, setSubmitLoading] = useState(false)

    useEffect(() => {
        loadCustomColor()
        addLineItem()

        // eslint-disable-next-line
    }, [])

    const loadCustomColor = async () => {
        setProductLoading(true)

        try {
            const data = await TintAPI.getProductCustomColor(user.accessToken)
            setProducts(data.products)
        } catch (e) {
            if (e.code === TintAPI.ErrorCode.NOT_AUTHED) {
                history.push('/logout')
            } else {
                setProducts(null)
            }
        }

        setProductLoading(false)
    }

    const addLineItem = () => {
        setLineItems([...lineItems, {
            variant: null,
            customCode: '',
            quantity: 1
        }])
    }

    const onAddItemClick = () => {
        addLineItem()
    }
    const onItemChange = (item, i) => {
        lineItems[i] = item
        setLineItems([...lineItems])
    }
    const onDeleteClick = (index) => {
        lineItems.splice(index, 1)
        setLineItems([...lineItems])
    }
    const onCreateOrderClick = async () => {
        setSubmitLoading(true)

        try {
            const data = await TintAPI.createDraftCustomColorOrder(user.accessToken, {
                items: lineItems.map(item => {
                    return {
                        type: item.variant.paint_type,
                        size: item.variant.paint_size,
                        code: item.customCode,
                        qty: item.quantity
                    }
                })
            })
            setCreateResult({ error: false, data: data })
        } catch (e) {
            if (e.code === TintAPI.ErrorCode.NOT_AUTHED) {
                history.push('/logout')
            } else if (e.code) {
                setCreateResult({ error: true, message: e.message ? e.message : e.code })
            } else {
                setCreateResult({ error: true, message: 'Failed to create order, check fields' })
            }
        }

        setSubmitLoading(false)
    }
    const onResetClick = () => {
        setLineItems([])
        setCreateResult(null)
    }


    return (
        <>
            <Space direction="vertical" size={32} style={{ width: '100%' }}>
                <TooltipTitle title="Create Draft Order" tooltip="Create a draft order with custom colour. Custom colour codes are attached to be fully compatible with the warehouse system." />
                {(!createResult || createResult.error) &&
                    <Spin spinning={productLoading}>
                        {products &&
                            <Space direction="vertical" size={12} style={{ width: '100%' }}>
                                <Row gutter={24}>
                                    <Col span={5}><Title level={5}>Custom Colour</Title></Col>
                                    <Col span={5}><Title level={5}>Custom Code</Title></Col>
                                    <Col span={2}><Title level={5}>Qty</Title></Col>
                                </Row>
                                {lineItems.map((item, i) => <TintMatchOrderLineItem key={i} products={products} defItem={item} onItemChange={(item) => onItemChange(item, i)} onDeleteClick={() => onDeleteClick(i)} />)}
                                <Button key="add-item-btn" onClick={onAddItemClick}>Add item</Button>
                                <Text type="secondary" style={{ marginTop: 48, display: 'block' }}>
                                    Ensure all selected base/size/type combinations are<br />available before creating order.
                                </Text>
                                <div>
                                    <Button key="create-order-btn" type="primary" size="large" loading={submitLoading} onClick={onCreateOrderClick}>Create order</Button>
                                    {createResult && createResult.error && <Text type="danger" style={{ marginLeft: 24 }}>{createResult.message}</Text>}
                                </div>
                            </Space>
                        }
                    </Spin>
                }

                {createResult && !createResult.error &&
                    <div>
                        <Text>Order was successfully created, complete process here:</Text>
                        <div style={{ marginTop: 8 }}><a href={createResult.data.order_url} rel="noopener noreferrer" target="_blank">{createResult.data.order_url}</a></div>
                        <Button key="reset-btn" size="small" style={{ marginTop: 36 }} onClick={onResetClick}>Create new order</Button>
                    </div>
                }
            </Space>
        </>
    )
}

const mapStateToProps = state => {
    const user = getUser(state)
    return { user }
}

export default connect(mapStateToProps)(DraftOrdersPanel)

