import { Col, Row, Space, Spin } from 'antd';
import { useState } from "react";
import TintAPI from "../utils/TintAPI";
import TooltipTitle from "../components/TooltipTitle";
import Search from "antd/lib/input/Search";
import TintMatchSearchList from "../components/TintMatchSearchList";
import Title from "antd/lib/typography/Title";
import useTintAPI from "../hooks/useTintAPI";

const TintMatchSearchPanel = () =>
{
    const [search, setSearch] = useState(null);
    const [data, loading] = useTintAPI(TintAPI.searchDigitalColor, search ? { query: search } : null, [search]);

    const onSearch = (search) =>
    {
        if (search.length > 0)
            setSearch(search);
    };

    const resultsVisibility = data ? 'visible' : 'hidden';

    return (
        <>
            <Space direction="vertical" size={32} style={{ width: '100%' }}>
                <TooltipTitle title="Tint Match Search" />
                <Search placeholder="Name, code, RGB, HEX, LAB" enterButton="Search" size="large" allowClear loading={loading} onSearch={onSearch} style={{ width: 400, display: 'block' }} />
                <Spin spinning={loading}>
                    <Title level={4} style={{ textAlign: 'center', marginTop: 32, visibility: resultsVisibility }}>Search results for <span style={{ color: '#1890ff' }}>{data?.query}</span></Title>
                    <Row gutter={24} style={{ marginTop: 24, visibility: resultsVisibility }}>
                        <Col flex="1 1 0"><TintMatchSearchList title="LAB" referenceHex={data?.lab_exact} items={data?.lab_matches} /></Col>
                        <Col flex="1 1 0"><TintMatchSearchList title="RGB" referenceHex={data?.rgb_exact} items={data?.rgb_matches} /></Col>
                        <Col flex="1 1 0"><TintMatchSearchList title="Name" items={data?.name_matches} /></Col>
                        <Col flex="1 1 0"><TintMatchSearchList title="Code" referenceHex={data?.code_exact} items={data?.code_matches} /></Col>
                    </Row>
                </Spin>
            </Space>
        </>
    );
};

export default TintMatchSearchPanel;

