import { Space, Spin } from 'antd'
import TintAPI from '../../utils/TintAPI'
import TooltipTitle from '../../components/TooltipTitle'
import Map from '../../components/analytics/Map'
import useTintAPI from '../../hooks/useTintAPI'
import SummaryRow from '../../components/SummaryRow'

const SampleConversionsMapAnalyticsPanel = () => {
    const ID_CLASS_SAMPLE_UNCONVERTED = 'sample_unconverted'
    const ID_CLASS_SAMPLE_CONVERTED = 'sample_converted'
    const ID_CLASS_SAMPLE_CONVERTED_REPEAT = 'sample_converted_repeat'
    const ID_CLASS_PAINT_DIRECT = 'paint_direct'
    const ID_CLASS_PAINT_DIRECT_REPEAT = 'paint_direct_repeat'
    const ID_CLASS_OTHER = 'other'

    const metaData = [
        {
            id: ID_CLASS_SAMPLE_UNCONVERTED,
            colors: ['#ff0000', '#be0000'],
            title: 'Samples unconverted',
            tooltip: 'Samples purchased, has never purchased paint.'
        },
        {
            id: ID_CLASS_SAMPLE_CONVERTED,
            colors: ['#00ccff', '#01b5cd'],
            title: 'Samples converted',
            tooltip: 'Samples unconverted customer purchased paint.'
        },
        {
            id: ID_CLASS_SAMPLE_CONVERTED_REPEAT,
            colors: ['#0088ff', '#016ecd'],
            title: 'Samples converted (repeat)',
            tooltip: 'Samples converted customer purchased paint again.'
        },
        {
            id: ID_CLASS_PAINT_DIRECT,
            colors: ['#62d451', '#54b346'],
            title: 'Paint direct',
            tooltip: 'Paint purchased, has never purchased samples.'
        },
        {
            id: ID_CLASS_PAINT_DIRECT_REPEAT,
            colors: ['#389d29', '#2a7920'],
            title: 'Paint direct (repeat)',
            tooltip: 'Paint direct customer purchased paint again.'
        },
        {
            id: ID_CLASS_OTHER,
            colors: ['#565656', '#3e3e3e'],
            title: 'Other',
            tooltip: 'Has only purchased non-paint/non-sample products.'
        }
    ]

    const [data, loading] = useTintAPI(TintAPI.getAnalyticsMap, {
        metric: TintAPI.Analytics.Metrics.CUSTOMER_JOURNEY
    }, [])

    const getClassification = feature => {
        if (feature.classifications.paint_repeat) {
            return feature.classifications.sample_converted ? ID_CLASS_SAMPLE_CONVERTED_REPEAT : ID_CLASS_PAINT_DIRECT_REPEAT
        } else if (feature.classifications.paint) {
            return feature.classifications.sample_converted ? ID_CLASS_SAMPLE_CONVERTED : ID_CLASS_PAINT_DIRECT
        } else if (feature.classifications.sample_unconverted) {
            return ID_CLASS_SAMPLE_UNCONVERTED
        }
        return ID_CLASS_OTHER
    }

    return (
        <Space direction="vertical" size={64} style={{ width: '100%' }}>
            <SummaryRow items={[{ title: 'Total Customers', value: data?.length }]} loading={loading} />
            <div>
                <TooltipTitle title="Sample Conversions Map" tooltip="Represents Shopify customers. Customer location based on shipping address of first order. Customers with an invalid location are ignored." style={{ marginBottom: 32 }} />
                <Spin spinning={loading}>
                    <Map data={data} metaData={metaData} getClassification={getClassification} loading={loading} />
                </Spin>
            </div>
        </Space>
    )
}

export default SampleConversionsMapAnalyticsPanel

