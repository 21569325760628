import { Space, Spin } from 'antd'
import TintAPI from '../../utils/TintAPI'
import { Pie } from '@ant-design/charts'
import Title from 'antd/lib/typography/Title'
import useTintAPI from '../../hooks/useTintAPI'
import SummaryRow from '../../components/SummaryRow'

const ColoursTintMatchBrandsAnalyticsPanel = () => {
    const [data, loading] = useTintAPI(TintAPI.getDigitalColorSummary, {}, [])

    const config = {
        data: data ? data.aggregation : [],
        angleField: 'count',
        colorField: 'key',
        radius: 0.8,
        label: {
            type: 'spider',
            labelHeight: 28,
            content: '{name}\n{percentage}'
        },
        interactions: [{ type: 'element-selected' }, { type: 'element-active' }]
    }

    return (
        <>
            <Space direction="vertical" size={64} style={{ width: '100%' }}>
                <SummaryRow items={[{ title: 'Total Tint Match Colours', value: data?.count }]} loading={loading} />
                <Title level={3}>Tint Match Brand Distribution</Title>
                <Spin spinning={loading}>
                    <Pie {...config} style={{ maxWidth: '800px', margin: '0 auto' }} />
                </Spin>
            </Space>
        </>
    )
}

export default ColoursTintMatchBrandsAnalyticsPanel

