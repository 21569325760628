import React from 'react';
import { Route, Redirect } from "react-router-dom";

const PrivateRoute = ({ component: Component, isAuthed, ...rest }) =>
{
    const render = props => (isAuthed ? (<Component {...props} />) : (<Redirect to={{ pathname: '/login', state: { from: props.location } }} />));

    return (
        <Route {...rest} render={render} />
    );
}

export default PrivateRoute
