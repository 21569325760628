import { Radio } from 'antd'
import { useState, Fragment } from 'react'
import './CohortTable.scss'
import moment from 'moment'
import AggregationUtils from '../../utils/AggregationUtils'
import TintAPI from '../../utils/TintAPI'

const CohortTable = ({ data, onCohortChange, onConversionDateChange }) => {
    const [selectedCellIndex, setSelectedCellIndex] = useState(null)
    const [displayType, setDisplayType] = useState('absolute')

    const isPercent = displayType === 'percentage'

    const maxConvertWeeks = data ? data.reduce((max, item) => {
        for (const id in item.convert_periods) {
            max = Math.max(max, parseInt(id))
        }
        return max
    }, 0) : 0
    const maxWeekColumns = maxConvertWeeks + 1

    const maxCellValue = data ? data.reduce((max, item) => {
        for (const id in item.convert_periods) {
            if (id === '-1') {
                continue
            }

            const value = isPercent ? Math.round(parseInt(item.convert_periods[id]) / item.converted * 100) : parseInt(item.convert_periods[id])
            max = Math.max(max, value)
        }
        return max
    }, 0) : 0

    const getCohortDateRangeStr = key => AggregationUtils.getIntervalString(key, TintAPI.Analytics.Group.TWO_MONTH)

    const getCellValue = (rawValue, total) => {
        if (!rawValue) {
            return null
        }
        return isPercent ? Math.round(rawValue / total * 100) : rawValue
    }

    const Cell = ({ className, value, isPercent, showEmphasis, rowSelected, cellSelected, style, onCellClick }) => {
        return <div className={`cell ${rowSelected ? 'row-selected' : ''} ${cellSelected ? 'cell-selected' : ''} ${className}`}
                    style={{ backgroundColor: showEmphasis ? `rgba(24, 144, 255, ${value / maxCellValue * 0.5})` : 'transparent', ...style }}
                    onClick={onCellClick}>
            {value}{value !== null && isPercent && <span style={{ fontSize: '0.7em', opacity: 0.5 }}>%</span>}
        </div>
    }


    const onCellClick = (index) => {
        if (index && selectedCellIndex && index[1] === selectedCellIndex[1]) {
            index = null
        }

        const cohort = data && index ? data[index[1]] : null

        if (cohort) {
            const dt = new moment(cohort.key)
            const conversionDate = {
                key: dt.add(index[0], 'week').format('YYYY-MM-DD'),
                conversions: []
            }

            const startIndex = index[1] + index[0]
            for (let cohortIndex = startIndex, weeks = 0; cohortIndex >= 0; cohortIndex--, weeks++) {
                if (cohortIndex >= data.length) {
                    conversionDate.conversions.push(0)
                    continue
                }

                conversionDate.conversions.push(data[cohortIndex].convert_periods[weeks] || 0)
            }

            onConversionDateChange(conversionDate)
        }

        onCohortChange(cohort)

        setSelectedCellIndex(index)
    }


    if (!data) {
        return <></>
    }

    return (
        <div className="cohort-chart">
            <div style={{ maxHeight: 600, display: 'grid', gridTemplateColumns: `150px 85px 85px repeat(${maxWeekColumns}, 40px)`, overflow: 'scroll' }}>
                <div className="cell cell-header left" style={{ position: 'sticky', top: 0, left: 0, zIndex: 10 }} />
                <div className="cell cell-header" style={{ position: 'sticky', top: 0, zIndex: 5 }} />
                <div className="cell cell-header left" style={{ position: 'sticky', top: 0, zIndex: 5 }} />
                <div className="cell cell-header" style={{ position: 'sticky', top: 0, zIndex: 5 }}>Week</div>
                {[...Array(maxWeekColumns - 1)].map((col, i) =>
                    <div className="cell cell-header" key={`top-header-${i}`} style={{ position: 'sticky', top: 0, zIndex: 5 }} />
                )}

                <div className="cell cell-header top left" style={{ position: 'sticky', top: 0, left: 0, zIndex: 10 }}>Cohort</div>
                <div className="cell cell-header top" style={{ position: 'sticky', top: 0, zIndex: 5 }}>New Sample Customers</div>
                <div className="cell cell-header top left" style={{ position: 'sticky', top: 0, zIndex: 5 }}>Lifetime Converted</div>
                {[...Array(maxWeekColumns)].map((col, i) =>
                    <div className="cell cell-header top" key={`bottom-header-${i}`} style={{ position: 'sticky', top: 0, zIndex: 5 }}>{i}</div>
                )}
                {data.map((item, i) =>
                    <Fragment key={`cohort-${i}`}>
                        <div className={`cell cell-header left ${selectedCellIndex && selectedCellIndex[1] === i ? 'row-selected' : ''}`} style={{ position: 'sticky', left: 0 }} onClick={() => onCellClick([0, i])}>{getCohortDateRangeStr(item.key)}</div>
                        <div className={`cell ${selectedCellIndex && selectedCellIndex[1] === i ? 'row-selected' : ''}`} style={{ fontWeight: 600 }} onClick={() => onCellClick([0, i])}>{item.count}</div>
                        <Cell className="left" value={getCellValue(item.converted, item.count)} isPercent={isPercent} rowSelected={selectedCellIndex && selectedCellIndex[1] === i} style={{ fontWeight: 600 }} onCellClick={() => onCellClick([0, i])} />
                        {[...Array(maxWeekColumns)].map((col, j) =>
                            <Cell key={`cell-${j}`} value={getCellValue(item.convert_periods[j], item.converted)} isPercent={isPercent} showEmphasis={true}
                                  rowSelected={selectedCellIndex && selectedCellIndex[1] === i} cellSelected={false && selectedCellIndex && selectedCellIndex[0] - j === i - selectedCellIndex[1]}
                                  onCellClick={() => onCellClick([j, i])} />
                        )}
                    </Fragment>
                )}
            </div>

            <Radio.Group defaultValue={displayType} style={{ textAlign: 'center', marginTop: 24, display: 'block' }} onChange={e => setDisplayType(e.target.value)}>
                <Radio.Button value="absolute">Absolute</Radio.Button>
                <Radio.Button value="percentage">Percentage</Radio.Button>
            </Radio.Group>
        </div>
    )
}

export default CohortTable

