import { Space, Spin, Table } from 'antd'
import { useState } from 'react'
import TintAPI from '../utils/TintAPI'
import DataPagination from '../components/DataPagination'
import useTintAPI from '../hooks/useTintAPI'
import SummaryRow from '../components/SummaryRow'

const NewYearNewHomeEntriesPanel = () => {
    const [page, setPage] = useState(1)
    const [data, loading] = useTintAPI(TintAPI.getNewYearNewHomeEntries, {
        page: page
    }, [page])

    let processedData = data?.new_year_new_home_entries

    if (processedData) {
        processedData.items = processedData.items.map((entry, i) => {
            return { ...entry, key: i }
        })
    }

    const columns = [
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email'
        },
        {
            title: 'First Name',
            dataIndex: 'first_name',
            key: 'first_name'
        },
        {
            title: 'Last Name',
            dataIndex: 'last_name',
            key: 'last_name'
        },
        {
            title: 'Instagram',
            dataIndex: 'instagram_handle',
            key: 'instagram_handle',
            render: (handle) => {
                if (handle) {
                    return <a href={`https://instagram.com/${handle.replace('@', '')}`} rel="noopener noreferrer"
                              target="_blank">{handle}</a>
                }
            }
        },
        {
            title: 'Referrer',
            dataIndex: 'referrer',
            key: 'referrer'
        },
        {
            title: 'Referred',
            dataIndex: 'referred',
            key: 'referred',
            render: (list) => (
                <div className="subtitle">
                    {list.map((item) => <div key={item}>{item}</div>)}
                </div>
            )
        }
    ]

    const onPageChange = (page) => {
        setPage(page)
    }

    return (
        <>
            <Space direction="vertical" size={64} style={{ width: '100%' }}>
                <SummaryRow items={[{ title: 'Total New Year New Home Entries', value: processedData?.paging.total_item_count }]} loading={loading} />
                <Spin spinning={loading}>
                    <Table columns={columns} dataSource={processedData?.items} pagination={false} />
                </Spin>
            </Space>
            <DataPagination paging={processedData?.paging} itemType="entries" onChange={onPageChange} />
        </>
    )
}

export default NewYearNewHomeEntriesPanel

