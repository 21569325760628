import { APPLY_LOCAL_SETTINGS } from '../actionTypes'

const reducer = (state = {}, action) => {
    switch (action.type) {
        case APPLY_LOCAL_SETTINGS: {
            if (!action.payload) {
                return state
            }

            return {
                ...state,
                showSummaryCounts: action.payload.showSummaryCounts
            }
        }
        default:
            return state
    }
}

export default reducer
