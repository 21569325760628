import { Row, Col, Input, InputNumber, Select } from 'antd';
import { CloseCircleFilled, LoadingOutlined } from '@ant-design/icons';
import { useEffect, useState } from "react";
import './TintMatchOrderLineItem.scss';
import Text from "antd/lib/typography/Text";
import TintAPI from "../utils/TintAPI";
import { useHistory } from "react-router-dom";
import {getUser} from "../redux/selectors";
import {connect} from "react-redux";
const { Option } = Select;

const TintMatchOrderLineItem = ({ user, products, defItem, onItemChange, onDeleteClick }) =>
{
    const history = useHistory();
    const [item, setItem] = useState(defItem);
    const [decodedCode, setDecodedCode] = useState(null);
    const [decodeLoading, setDecodeLoading] = useState(false);
    const [decodeResult, setDecodeResult] = useState(null);

    useEffect(() =>
    {
        onItemChange(item);

        // eslint-disable-next-line
    }, [item]);

    const decodeCode = async (code) =>
    {
        if (code === decodedCode)
            return;

        setDecodeLoading(true);
        setDecodedCode(code);
        setDecodeResult(null);

        try
        {
            const data = await TintAPI.getDigitalColorMatches(user.accessToken, code, 1);
            if (data.matches.length > 0)
                setDecodeResult({ error: false, data: data.matches[0] });
            else
                setDecodeResult({ error: true });
        }
        catch (e)
        {
            if (e.code === TintAPI.ErrorCode.NOT_AUTHED)
                history.push('/logout');
            else
                setDecodeResult({ error: true });
        }

        setDecodeLoading(false);
    };


    const onVariantChange = (id) =>
    {
        setItem({ ...item, variant: products.find(p => id === p.variants[0].id).variants[0] });
    };
    const onCustomCodeChange = async (e) =>
    {
        const code = e.target.value.toUpperCase();
        setItem({ ...item, customCode: code });
    };
    const onCustomCodeBlur = async (e) =>
    {
        await decodeCode(e.target.value.toUpperCase());
    };
    const onQtyChange = (qty) =>
    {
        setItem({ ...item, quantity: qty });
    };


    return (
        <div className="tint-match-order-line-item">
            <Row gutter={12} style={{ display: 'flex', alignItems: 'center' }}>
                <Col span={5}>
                    <Select value={item.variant?.title} defaultValue={item.variant?.title.replace(' / ', ' ')} size="large" style={{ width: '100%' }} onChange={onVariantChange}>
                        {products.map(p => <Option key={p.variants[0].id} value={p.variants[0].id}>{p.variants[0].title.replace(' / ', ' ')}</Option>)}
                    </Select>
                </Col>
                <Col span={5}>
                    <Input value={item.customCode} defaultValue={item.customCode} suffix={decodeLoading && <LoadingOutlined />} size="large" style={{ width: '100%' }} onChange={onCustomCodeChange} onBlur={onCustomCodeBlur} />
                </Col>
                <Col span={2}>
                    <InputNumber value={item.quantity} defaultValue={item.quantity} min={1} max={99} size="large" style={{ width: '100%' }} onChange={onQtyChange} />
                </Col>
                <Col className="delete-btn-col" span={2} onClick={() => onDeleteClick()}>
                    <CloseCircleFilled className="delete-btn" />
                </Col>
            </Row>
            {decodeResult &&
                <Row gutter={12} style={{ marginTop: 8, marginBottom: 8, display: 'flex', alignItems: 'center' }}>
                    <Col className="color-col" offset={5} span={9}>
                        {!decodeResult.error && <div className="color-circle" style={{ backgroundColor: decodeResult.data.hex }} />}
                        <div>
                            {decodeResult.error && <Text className="color-text" type="danger">Invalid code</Text>}
                            {!decodeResult.error &&
                                <>
                                    <Text className="color-text">{decodeResult.data.brand} {decodeResult.data.name}</Text>
                                    <Text className="color-text" type="secondary" style={{ fontSize: 12 }}>{decodeResult.data.formula.base}</Text>
                                </>
                            }
                        </div>
                    </Col>
                </Row>
            }
        </div>
    );
};

const mapStateToProps = state =>
{
    const user = getUser(state);
    return { user };
};

export default connect(mapStateToProps)(TintMatchOrderLineItem);
