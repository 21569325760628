const HOST = `https://api${process.env.REACT_APP_ENV !== 'production' ? '-test' : ''}.tintpaint.com`


const TintAPI = {
    SHOP_HOST: `https://tintpaint${process.env.REACT_APP_ENV !== 'production' ? '-dev' : ''}.myshopify.com`,
    ErrorCode: {
        NOT_AUTHED: 'NOT_AUTHED',
        AUTH_GATEWAY: 'AUTH_GATEWAY'
    },
    Analytics: {
        Metrics: {
            USER_COUNT: 'user_count',
            GUEST_USER_COUNT: 'guest_user_count',
            TINT_PRO_APPLICATION_COUNT: 'tint_pro_application_count',
            CUSTOMER_SAMPLE_JOURNEY_START_COHORT: 'customer_sample_journey_start_cohort',
            CUSTOMER_SAMPLE_JOURNEY_END_COHORT: 'customer_sample_journey_end_cohort',
            ORDER_COUNT: 'order_count',
            ORDER_VALUE: 'order_value',
            ORDER_DELIVERY_FEE: 'order_delivery_fee',
            ORDER_DELIVERY_METHOD: 'order_delivery_method',
            ORDER_REFUNDS: 'order_refunds',
            ORDER_ITEM_PAINT_LITRES: 'order_item_paint_litres',
            ORDER_ITEM_CUSTOM_COLOR_MATCH_QUALITY: 'order_item_custom_color_match_quality',
            ORDER_DISPATCH_UNFULFILLED: 'order_dispatch_unfulfilled',
            ORDER_DISPATCH_PAINT_TIME: 'order_dispatch_paint_time',
            ORDER_DISPATCH_NON_PAINT_TIME: 'order_dispatch_non_paint_time',

            CUSTOMER_JOURNEY: 'customer_journey',
            CUSTOMER_SAMPLE_JOURNEY: 'customer_sample_journey',
            ORDER_CONTENT: 'order_content',
            ORDER_COLOR: 'order_color'
        },
        Group: {
            DAY: 'day',
            WEEK: 'week',
            MONTH: 'month',
            TWO_MONTH: 'two_month',
            HOUR_OF_DAY: 'hour_of_day',
            DAY_OF_WEEK: 'day_of_week'
        },
        Accumulator: {
            TOTAL: 'total'
        }
    },

    signInPassword: async function(email, password) {
        const data = await postRequest('admin/sign_in', {
            email: email,
            password: password
        })

        return {
            authFlowToken: data.auth_flow_token
        }
    },
    signInCode: async function(authFlowToken, code) {
        const dataSignIn = await postRequest('admin/sign_in', {
            auth_flow_token: authFlowToken,
            code: code
        })

        const dataProfile = await getAdminProfile(dataSignIn.storefront_token)

        return {
            email: dataProfile.email,
            accessToken: dataSignIn.storefront_token
        }
    },

    signOut: async function(storefrontToken) {
        const data = await postRequest('auth/sign_out', {
            storefront_token: storefrontToken
        })

        return data
    },

    createDraftCustomColorOrder: async function(storefrontToken, params) {
        return await postRequest('admin/orders/draft_custom_color_create', {
            storefront_token: storefrontToken,
            items: params.items
        })
    },

    getProducts: async function(storefrontToken, params) {
        return await getRequest('admin/products', {
            storefront_token: storefrontToken,
            ...(params.search && { search: params.search }),
            page: params.page ? params.page : 1,
            ...(params.sort && params.sort.field && { sort_field: params.sort.field }),
            ...(params.sort && params.sort.dir && { sort: params.sort.dir })
        })
    },
    getProductCustomColor: async function(storefrontToken) {
        return await getRequest('admin/products/custom_color', {
            storefront_token: storefrontToken
        })
    },

    getDigitalColors: async function(storefrontToken, params) {
        return await getRequest('admin/digital_color', {
            storefront_token: storefrontToken,
            ...(params.search && { search: params.search }),
            page: params.page ? params.page : 1,
            ...(params.sort && params.sort.field && { sort_field: params.sort.field }),
            ...(params.sort && params.sort.dir && { sort: params.sort.dir })
        })
    },
    getDigitalColorSummary: async function(storefrontToken) {
        return await getRequest('admin/digital_color/summary', {
            storefront_token: storefrontToken
        })
    },
    getDigitalColorMatches: async function(storefrontToken, customCode, count = 5, promoteTint = false) {
        return await getRequest('admin/digital_color/matches', {
            storefront_token: storefrontToken,
            code: customCode,
            count: count,
            ...(promoteTint && { promote_brand: 'tint' }),
            show: 'hex,lab,code,formula'
        })
    },
    searchDigitalColor: async function(storefrontToken, params) {
        return await getRequest('admin/digital_color/search', {
            storefront_token: storefrontToken,
            query: params.query
        })
    },

    getUsers: async function(storefrontToken, params) {
        return await getRequest('admin/users', {
            storefront_token: storefrontToken,
            page: params.page ? params.page : 1,
            ...(params.sort && params.sort.field && { sort_field: params.sort.field }),
            ...(params.sort && params.sort.dir && { sort: params.sort.dir })
        })
    },

    getGuestUsers: async function(storefrontToken, params) {
        return await getRequest('admin/guest_users', {
            storefront_token: storefrontToken,
            ...(params.search && { search: params.search }),
            page: params.page ? params.page : 1
        })
    },

    getFavoritesSummary: async function(storefrontToken) {
        return await getRequest('admin/favorites/summary', {
            storefront_token: storefrontToken
        })
    },

    addTintProUser: async function(storefrontToken, email, firstName, lastName) {
        return await postRequest('admin/tint_pro/user', {
            storefront_token: storefrontToken,
            user_email: email,
            ...(firstName && { first_name: firstName }),
            ...(lastName && { last_name: lastName })
        })
    },
    getTintProApplications: async function(storefrontToken, params) {
        return await getRequest('admin/tint_pro/applications', {
            storefront_token: storefrontToken,
            ...(params.search && { search: params.search }),
            page: params.page ? params.page : 1
        })
    },

    getTintStylistCustomers: async function(storefrontToken, params) {
        return await getRequest('admin/stylist/customers', {
            storefront_token: storefrontToken,
            ...(params.search && { search: params.search }),
            ...(params.start && { start: params.start }),
            ...(params.end && { end: params.end }),
            page: params.page ? params.page : 1
        })
    },
    getTintStylistOrders: async function(storefrontToken, params) {
        return await getRequest('admin/stylist/orders', {
            storefront_token: storefrontToken,
            ...(params.search && { search: params.search }),
            ...(params.start && { start: params.start }),
            ...(params.end && { end: params.end }),
            page: params.page ? params.page : 1
        })
    },

    getNewYearNewHomeEntries: async function(storefrontToken, params) {
        return await getRequest('admin/event/new_year_new_home_entries', {
            storefront_token: storefrontToken,
            page: params.page ? params.page : 1
        })
    },

    getAnalyticsAggregation: async function(storefrontToken, params) {
        return await getRequest('admin/analytics/aggregation', {
            storefront_token: storefrontToken,
            metrics: params.metrics,
            ...(params.group && { group: params.group }),
            ...(params.start && { start: params.start }),
            ...(params.end && { end: params.end })
        })
    },
    getAnalyticsMap: async function(storefrontToken, params) {
        return await getRequest('admin/analytics/map', {
            storefront_token: storefrontToken,
            metric: params.metric,
            ...(params.start && { start: params.start }),
            ...(params.end && { end: params.end })
        })
    }
}

async function getAdminProfile(storefrontToken) {
    return await getRequest('admin/profile', {
        storefront_token: storefrontToken
    })
}

async function getRequest(url, params) {
    return await request(url, params, 'GET')
}

async function postRequest(url, params) {
    return await request(url, params, 'POST')
}

async function request(path, params, method) {
    let url = `${HOST}/${path}`
    let opts = {}

    if (method === 'GET') {
        let queryStr = ''
        for (let key in params) {
            queryStr += queryStr.length === 0 ? '?' : '&'
            queryStr += `${key}=${encodeURIComponent(params[key])}`
        }

        url += queryStr
    } else {
        opts = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(params)
        }
    }


    const res = await fetch(url, opts)
    const obj = await res.json()

    if (obj.error) {
        throw new APIError(obj.error.code, obj.error.message)
    }

    return obj.data
}


class APIError extends Error {
    constructor(code, message) {
        super(message)
        this.code = code
    }
}


export default TintAPI
