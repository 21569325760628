import { useEffect, useState } from 'react'
import { store } from '../redux/store'
import TintAPI from '../utils/TintAPI'
import { useHistory } from 'react-router-dom'

const useTintAPI = (func, params = {}, deps = null) => {
    const history = useHistory()
    const [data, setData] = useState(null)
    const [loading, setLoading] = useState(false)
    const [authed, setAuthed] = useState(true)

    useEffect(() => {
        if (params) {
            loadData()
        }

        // eslint-disable-next-line
    }, deps)

    const loadData = async () => {
        setData(null)
        setLoading(true)

        try {
            const data = await func(store.getState().user.accessToken, params)
            setData(data)
        } catch (e) {
            if (e.code === TintAPI.ErrorCode.NOT_AUTHED) {
                setAuthed(false)
            } else {
                setData(null)
            }
        }

        setLoading(false)
    }

    if (!authed) {
        history.push('/logout');
    }

    return [data, loading];
};

export default useTintAPI;
