import { Space, Spin, Table, Tag } from 'antd'
import { useState } from 'react'
import TintAPI from '../utils/TintAPI'
import DataPagination from '../components/DataPagination'
import moment from 'moment'
import useTintAPI from '../hooks/useTintAPI'
import DateSearchInput from '../components/DateSearchInput'
import SummaryRow from '../components/SummaryRow'

const TintStylistOrdersPanel = () => {
    const [search, setSearch] = useState(null)
    const [dateRange, setDateRange] = useState([null, null])
    const [page, setPage] = useState(1)
    const [data, loading] = useTintAPI(TintAPI.getTintStylistOrders, {
        search: search,
        start: dateRange[0],
        end: dateRange[1],
        page: page
    }, [search, dateRange, page])

    const onSearch = (search) => {
        setPage(1)
        setSearch(search)
    }
    const onDateRangeChange = (range) => {
        setPage(1)
        setDateRange(range)
    }
    const onPageChange = (page) => {
        setPage(page)
    }


    const columns = [
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            render: (value, row) => <a href={`${TintAPI.SHOP_HOST}/admin/customers/${row.customer_id}`} rel="noopener noreferrer" target="_blank">{value}</a>
        },
        {
            title: 'First Post Stylist Order',
            dataIndex: 'first_post_stylist_order',
            key: 'first_post_stylist_order',
            render: (flag) => <Tag color={flag ? 'green' : null}>{flag && 'YES'}</Tag>,
        },
        {
            title: 'Order Value',
            dataIndex: 'order_value',
            key: 'order_value',
            render: (value) => <div style={{ color: value > 0 ? '#000' : '#888' }}>{value.toMoneyString()}</div>,
        },
        {
            title: 'Ordered',
            dataIndex: 'created_at',
            key: 'created_at',
            render: (created_at) => new moment(created_at).format('DD/MM/YY'),
        },
    ]

    return (
        <>
            <Space direction="vertical" size={48} style={{ width: '100%' }}>
                <SummaryRow items={[
                    { title: 'Total Tint Stylist Orders', value: data?.paging.total_item_count },
                    { title: 'Total Order Value', value: data?.summary.total_order_value?.toMoneyString(), tooltip: 'Order value is defined as the price of all the items in the order after both line-item and cart discounts have been applied. Excludes shipping costs and refunds.' },
                    { title: 'Average Order Value', value: data && data.paging.total_item_count > 0 ? (data.summary.total_order_value / data.paging.total_item_count).toMoneyString() : '-' },
                    { title: 'First Post Stylist Orders', value: data?.summary.first_post_stylist_order_count, tooltip: 'Defined as the first order made by a customer after their first Tint Stylist booking.' }
                ]} loading={loading} />
                <div>
                    <DateSearchInput loading={loading} onSearch={onSearch} onDateRangeChange={onDateRangeChange} />
                    <Spin spinning={loading}>
                        <Table columns={columns} dataSource={data?.items} rowKey={item => item.email + item.created_at} pagination={false} />
                    </Spin>
                </div>
            </Space>
            <DataPagination paging={data?.paging} itemType="orders" onChange={onPageChange} />
        </>
    )
}

export default TintStylistOrdersPanel

