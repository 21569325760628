import { Space, Spin } from 'antd'
import TintAPI from '../../utils/TintAPI'
import TooltipTitle from '../../components/TooltipTitle'
import Map from '../../components/analytics/Map'
import useTintAPI from '../../hooks/useTintAPI'
import SummaryRow from '../../components/SummaryRow'

const OrdersContentMapAnalyticsPanel = () => {
    const ID_CLASS_PAINT = 'paint'
    const ID_CLASS_SAMPLE = 'sample'
    const ID_CLASS_ACCESSORY = 'accessory'
    const ID_CLASS_OTHER = 'other'

    const metaData = [
        { id: ID_CLASS_PAINT, colors: ['#62d451', '#54b346'], title: 'Paint' },
        { id: ID_CLASS_SAMPLE, colors: ['#0088ff', '#016ecd'], title: 'Samples, no paint' },
        { id: ID_CLASS_ACCESSORY, colors: ['#ff8400', '#d96f00'], title: 'Accessories, no paint/samples' },
        { id: ID_CLASS_OTHER, colors: ['#565656', '#3e3e3e'], title: 'Other' }
    ]

    const [data, loading] = useTintAPI(TintAPI.getAnalyticsMap, {
        metric: TintAPI.Analytics.Metrics.ORDER_CONTENT
    }, [])

    const getClassification = feature => {
        if (feature.classifications.predefined_paint || feature.classifications.custom_paint || feature.classifications.prep_ceiling_paint) {
            return ID_CLASS_PAINT
        } else if (feature.classifications.sample) {
            return ID_CLASS_SAMPLE
        } else if (feature.classifications.accessory) {
            return ID_CLASS_ACCESSORY
        }
        return ID_CLASS_OTHER
    }


    return (
        <Space direction="vertical" size={64} style={{ width: '100%' }}>
            <SummaryRow items={[{ title: 'Total Orders', value: data?.length }]} loading={loading} />
            <div>
                <TooltipTitle title="Order Content Map" tooltip="Order location based on shipping address. Orders without a valid shipping address are ignored." style={{ marginBottom: 32 }} />
                <Spin spinning={loading}>
                    <Map data={data} metaData={metaData} getClassification={getClassification} loading={loading} />
                </Spin>
            </div>
        </Space>
    )
}

export default OrdersContentMapAnalyticsPanel

