import { useState } from 'react'

const LegendView = ({ items, style, onActiveChange }) => {
    const [activeStates, setActiveStates] = useState(items.map(active => true))

    const onLegendItemClick = index => {
        const actives = activeStates.slice()
        const active = !actives[index]
        actives[index] = active
        setActiveStates(actives)

        if (onActiveChange) {
            onActiveChange(items.filter((item, i) => actives[i]))
        }
    };

    return (
        <div style={style}>
            {items.map((item, i) =>
                <div key={i}  style={{ lineHeight: 1.3, display: 'inline-block', cursor: 'pointer', userSelect: 'none', opacity: activeStates[i] ? 1 : 0.3 }} onClick={() => onLegendItemClick(i)}>
                    <div style={{ marginRight: 24, display: 'flex', alignItems: 'center' }}>
                        <div style={{ backgroundColor: item.colors[0], border: `1px solid ${item.colors[1]}`, borderRadius: 4, width: 8, height: 8, marginRight: 8 }} />{item.title}
                    </div>
                    <div className="subtitle" style={{ marginLeft: 16 }}>{item.count ? item.count.toLocaleString() : '-'}</div>
                </div>
            )}
        </div>
    )
}

export default LegendView

