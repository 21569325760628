import { SET_USER } from '../actionTypes'

const reducer = (state = null, action) => {
    switch (action.type) {
        case SET_USER: {
            if (!action.payload) {
                return null
            }

            return {
                email: action.payload.email,
                accessToken: action.payload.accessToken
            }
        }
        default:
            return state
    }
}

export default reducer
