import { useState } from 'react'
import { Button, Dropdown, Menu } from 'antd'
import { CaretDownOutlined } from '@ant-design/icons'
import TintAPI from '../../utils/TintAPI'

const IntervalSelector = ({ mode, defInterval, onChange }) => {
    let INTERVALS = {}
    if (mode === 'extended') {
        INTERVALS[TintAPI.Analytics.Group.DAY] = { id: TintAPI.Analytics.Group.DAY, title: 'Day' }
    }
    INTERVALS[TintAPI.Analytics.Group.WEEK] = { id: TintAPI.Analytics.Group.WEEK, title: 'Week' }
    INTERVALS[TintAPI.Analytics.Group.MONTH] = { id: TintAPI.Analytics.Group.MONTH, title: 'Month' }
    if (mode === 'extended') {
        INTERVALS[TintAPI.Analytics.Group.TWO_MONTH] = { id: TintAPI.Analytics.Group.TWO_MONTH, title: 'Two month' }
        INTERVALS[TintAPI.Analytics.Group.HOUR_OF_DAY] = { id: TintAPI.Analytics.Group.HOUR_OF_DAY, title: 'Hour of day' }
        INTERVALS[TintAPI.Analytics.Group.DAY_OF_WEEK] = { id: TintAPI.Analytics.Group.DAY_OF_WEEK, title: 'Day of week' }
    }

    const [interval, setInterval] = useState(INTERVALS[defInterval || TintAPI.Analytics.Group.WEEK])

    const onMenuItemClick = ({ key }) => {
        setInterval(INTERVALS[key])
        onChange(key)
    }

    const TheMenu = (
        <Menu onClick={onMenuItemClick}>
            {Object.keys(INTERVALS).map(key =>
                <Menu.Item key={key}>{INTERVALS[key].title}</Menu.Item>
            )}
        </Menu>
    )

    return (
        <Dropdown overlay={TheMenu} trigger={['click']}>
            <Button>{interval.title}<CaretDownOutlined /></Button>
        </Dropdown>
    )
}

export default IntervalSelector;

