import React from 'react';
import { Switch, Route } from 'react-router';
import './App.scss';
import LoginPage from "./pages/LoginPage";
import DashboardPage from "./pages/DashboardPage";
import PrivateRoute from "./middleware/PrivateRoute";
import { getUser } from "./redux/selectors";
import { connect } from "react-redux";
import LogoutPage from "./pages/LogoutPage";
import { Redirect } from "react-router-dom";

const App = ({ user }) => (
    <div className="App">
        <Switch>
            <Route exact path="/login" component={LoginPage} />
            <Route exact path="/logout" component={LogoutPage} />
            <PrivateRoute path="/" component={DashboardPage} isAuthed={user} />
            <Redirect to="/" />
        </Switch>
    </div>
);

const mapStateToProps = state =>
{
    const user = getUser(state);
    return { user };
};

export default connect(mapStateToProps)(App);
