import { Tooltip } from 'antd';
import Title from "antd/lib/typography/Title";
import { QuestionCircleOutlined } from "@ant-design/icons";

const TooltipTitle = ({ title, tooltip, style }) =>
{
    return (
        <Title level={3} style={{ display: 'flex', alignItems: 'center', ...style }}>{title}
            {tooltip && <Tooltip title={tooltip}><QuestionCircleOutlined style={{ fontSize: '16px', color: '#888', marginLeft: '8px' }} /></Tooltip>}
        </Title>
    );
};

export default TooltipTitle;

