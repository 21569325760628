import { APPLY_UI_SETTINGS } from '../actionTypes'

const reducer = (state = {}, action) => {
    switch (action.type) {
        case APPLY_UI_SETTINGS: {
            if (!action.payload) {
                return state
            }

            return {
                ...state,
                sidebarCollapsed: action.payload.sidebarCollapsed,
            }
        }
        default:
            return state
    }
}

export default reducer
