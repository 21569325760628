import { Space } from 'antd';
import TintAPI from "../../../utils/TintAPI";
import AggregateChart from "../../../components/analytics/AggregateChart";
import useTintAPI from "../../../hooks/useTintAPI";
import { useMemo, useState } from "react";
import AggregationUtils from "../../../utils/AggregationUtils";
import DateUtils from "../../../utils/DateUtils";
import AggregateTitle from "../../../components/analytics/AggregateTitle";

const AccountsNewsletterAnalyticsSection = () =>
{
    const [interval, setInterval] = useState(TintAPI.Analytics.Group.WEEK);
    const [dateRange, setDateRange] = useState(DateUtils.defEpochRange());
    const [aggregation, loading] = useTintAPI(TintAPI.getAnalyticsAggregation, {
        metrics: TintAPI.Analytics.Metrics.GUEST_USER_COUNT,
        group: interval,
        start: dateRange[0],
        end: dateRange[1]
    }, [interval, dateRange]);

    const processedAggregation = useMemo(() =>
    {
        if (!aggregation)
            return null;

        let result = AggregationUtils.addMissingInterval(aggregation.aggregations[0].items, aggregation.aggregations[0].group);
        result = AggregationUtils.addRunningTotals(result);
        return result;

    }, [aggregation]);


    return (
        <>
            <Space direction="vertical" size={64} style={{ width: '100%' }}>
                <AggregateTitle title="Newsletter Signups" tooltip="Represents visitors who have signed up to the newsletter or colour club via the Tint website excluding checkout."
                                suffix="signups" aggregation={processedAggregation} />
                <AggregateChart aggregation={processedAggregation} isLoading={loading}
                                defInterval={interval} defDateRange={dateRange}
                                intervalSelectorMode="basic" showDateRangePicker={true}
                                onIntervalChange={setInterval} onDateRangeChange={setDateRange} />
            </Space>
        </>
    );
};

export default AccountsNewsletterAnalyticsSection;

