import { DualAxes } from '@ant-design/charts'
import { Radio, Spin } from 'antd'
import { useState } from 'react'
import TintAPI from '../../utils/TintAPI'
import DateRangePicker from '../DateRangePicker'
import IntervalSelector from './IntervalSelector'
import AggregationUtils from '../../utils/AggregationUtils'

const StackedAggregateChart = ({
                                   stackedAggregation, lineAggregation, isLoading,
                                   columnType, columnColors, yField, lineId, lineType, lineTitle,
                                   defInterval, defDateRange,
                                   intervalSelectorMode, showPercentageToggle, showDateRangePicker,
                                   onIntervalChange, onDateRangeChange
                               }) => {
    yField = yField || 'count'
    lineId = lineId || 'total'
    const [interval, setInterval] = useState(defInterval || TintAPI.Analytics.Group.WEEK)
    const [displayType, setDisplayType] = useState('absolute')
    const [dateRange, setDateRange] = useState(defDateRange || [null, null])

    const meta = {
        key: {
            alias: 'Week',
            formatter: key => AggregationUtils.getIntervalString(key, interval)
        }
    }

    meta[yField] = {
        alias: lineTitle ? lineTitle : 'Count',
        formatter: (count) => columnType === 'money' ? count.toMoneyString() : count.toLocaleString()
    }
    meta[lineId] = {
        alias: lineTitle ? lineTitle : 'Total',
        formatter: (value) => {
            if (!value) {
                return null
            }
            return lineType === 'percent' ? Math.round(value) + '%' : value.toLocaleString()
        }
    }

    if (lineType === 'percent') {
        meta[lineId].min = 0
        meta[lineId].max = 100
    }

    const config = {
        data: [stackedAggregation || [], lineAggregation || []],
        xField: 'key',
        yField: [yField, lineId],
        geometryOptions: [
            {
                geometry: 'column',
                isStack: true,
                isPercent: displayType === 'percentage',
                seriesField: 'type',
                color: columnColors
            },
            {
                geometry: 'line',
                lineStyle: { lineWidth: 2 }
            }
        ],
        meta: meta
    }

    const onIntervalChangeInternal = (interval) => {
        setInterval(interval)
        onIntervalChange(interval)
    }
    const onDateRangeChangeInternal = range => {
        setDateRange(range)
        onDateRangeChange(range);
    };


    return (
        <Spin spinning={isLoading}>
            <DualAxes {...config} style={{ height: 400 }} />
            <div style={{ marginTop: 24, display: 'flex' }}>
                <div style={{ flex: '1 1 0' }}>
                    {intervalSelectorMode && <IntervalSelector mode={intervalSelectorMode} defValue={interval} onChange={interval => onIntervalChangeInternal(interval)} />}
                </div>
                <div style={{ flex: '1 1 0' }}>
                    {showPercentageToggle &&
                        <Radio.Group defaultValue={displayType} style={{ textAlign: 'center', display: 'block' }} onChange={e => setDisplayType(e.target.value)}>
                            <Radio.Button value="absolute">Absolute</Radio.Button>
                            <Radio.Button value="percentage">Percentage</Radio.Button>
                        </Radio.Group>
                    }
                </div>
                <div style={{ textAlign: 'right', flex: '1 1 0' }}>
                    {showDateRangePicker &&
                        <DateRangePicker defValue={dateRange} onChange={onDateRangeChangeInternal} />
                    }
                </div>
            </div>
        </Spin>
    );
};

export default StackedAggregateChart;

